import * as yup from "yup";
// hooks
import { useEffect } from "react";
import { useFormik } from "formik";
import {
  useCaseSettingsStore,
  useRzsStore,
} from "../../../../../../../../../store/hooks";
// types
import { TRzsStage03 } from "./type";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../../../../../apiHooks";

const validationSchema = yup.object().shape({
  programEntryDate: yup.string().required(),
  das28ResultMoi: yup.string().required(),
  resultMoi: yup.number().required(),
  vasResultMoi: yup.number().required(),
  obResultMoi: yup
    .number()
    .nullable()
    .when("das28ResultMoi", {
      is: (value: string) => value === "crp",
      then: (schema) => schema.required(),
    }),
  crpResultMoi: yup
    .number()
    .nullable()
    .when("das28ResultMoi", {
      is: (value: string) => value === "ob",
      then: (schema) => schema.required(),
    }),
  tenderJointsCountMoi: yup.number().min(0).max(10).required(),
  swollenJointsCountMoi: yup.number().min(0).max(10).required(),
  treatmentLines: yup.array().of(
    yup.object().shape({
      variants: yup.string().required(),
      otherVariantType: yup.string().when("variants", {
        is: (value: string) => value === "Inne",
        then: (schema) => schema.required(),
      }),
      dose: yup.string().when("variants", {
        is: (value: string) => value === "Inne",
        then: (schema) => schema.required(),
      }),
      tnfInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory TNF",
        then: (schema) => schema.required(),
      }),
      adalimumabOtherVariant: yup.string().when("tnfInhibitorType", {
        is: (value: string) => value === "Adalimumab inne",
        then: (schema) => schema.required(),
      }),
      adalimumabOtherDose: yup.string().when("tnfInhibitorType", {
        is: (value: string) => value === "Adalimumab inne",
        then: (schema) => schema.required(),
      }),
      il6Dose: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory IL-6",
        then: (schema) => schema.required(),
      }),
      jakInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory JAK",
        then: (schema) => schema.required(),
      }),
      monotherapy: yup.string().required(),
      combinationWithMedicine: yup
        .array()
        .of(
          yup.object().shape({
            combinationDrugName: yup.string().required(),
            otherCombinationDrug: yup.string().when("combinationDrugName", {
              is: (value: string) => value === "Inne",
              then: (schema) => schema.required(),
            }),
            combinationDose: yup.number().required(),
            combinationUnit: yup.string().required(),
            otherCombinationUnit: yup.string().when("combinationUnit", {
              is: (value: string) => value === "Inna jednostka",
              then: (schema) => schema.required(),
            }),
            combinationDuration: yup.number().required(),
          })
        )
        .when("monotherapy", {
          is: (value: string) => value === "kombinacja",
          then: (schema) => schema.min(1).required(),
        }),
      das28FollowupResult: yup.string().required(),
      followupResult: yup.number().required(),
      followupVasResult: yup.number().required(),
      followupObResult: yup
        .number()
        .nullable()
        .when("das28FollowupResult", {
          is: (value: string) => value === "crp",
          then: (schema) => schema.required(),
        }),
      followupCrpResult: yup
        .number()
        .nullable()
        .when("das28FollowupResult", {
          is: (value: string) => value === "ob",
          then: (schema) => schema.required(),
        }),
      followupTenderJointsCount: yup.number().min(0).max(10).required(),
      followupSwollenJointsCount: yup.number().min(0).max(10).required(),
      symptomsOfIntolerance: yup.string().required(),
    })
  ),
});

const initialValues: TRzsStage03 = {
  programEntryDate: null,
  das28ResultMoi: "",
  resultMoi: null,
  vasResultMoi: null,
  obResultMoi: null,
  crpResultMoi: null,
  tenderJointsCountMoi: null,
  swollenJointsCountMoi: null,
  treatmentLines: [
    {
      variants: "",
      otherVariantType: "",
      dose: "",
      tnfInhibitorType: "",
      adalimumabOtherVariant: "",
      adalimumabOtherDose: "",
      il6Dose: "",
      jakInhibitorType: "",
      monotherapy: "",
      combinationWithMedicine: [],
      das28FollowupResult: "",
      followupResult: null,
      followupVasResult: null,
      followupObResult: null,
      followupCrpResult: null,
      followupTenderJointsCount: null,
      followupSwollenJointsCount: null,
      symptomsOfIntolerance: "",
    },
  ],
};

export const useStage03ViewModel = () => {
  // params and location
  const params = useParams();
  const { area, form, caseId } = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {
    isNextButtonClicked,
    isPrevButtonClicked,
    currentStage,
    statusDraftButtonClicked,
  } = caseSettingsStore;

  // rzs store
  const rzsStore = useRzsStore();
  const { thirdStage, caseStage } = rzsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(thirdStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TRzsStage03>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false
  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await formik.submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  useEffect(() => {
    if (statusDraftButtonClicked === "click" && rzsStore.case !== null) {
      rzsStore.setCase({
        ...rzsStore.case,
        ...formik.values,
      });
      caseSettingsStore.saveStageDraftButton();
    }
  }, [statusDraftButtonClicked, formik.values, rzsStore, caseSettingsStore]);

  const { setFieldValue } = formik;

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    await formik.submitForm();
  };

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`treatmentLines[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `treatmentLines[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const deleteTreatment = (index: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines.filter((el, idx) => idx !== index),
    ];
    setFieldValue("treatmentLines", filteredItems);
  };

  const addLine = () => {
    setFieldValue("treatmentLines", [
      ...formik.values.treatmentLines,
      {
        variants: "",
        otherVariantType: "",
        dose: "",
        tnfInhibitorType: "",
        adalimumabOtherVariant: "",
        adalimumabOtherDose: "",
        il6Dose: "",
        jakInhibitorType: "",
        monotherapy: "",
        combinationWithMedicine: [],
        das28FollowupResult: "",
        followupResult: null,
        followupVasResult: null,
        followupObResult: null,
        followupCrpResult: null,
        followupTenderJointsCount: null,
        followupSwollenJointsCount: null,
        symptomsOfIntolerance: "",
      },
    ]);
  };

  const addCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `treatmentLines[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      ...formik.values.treatmentLines[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  return {
    formik,
    onSubmitHandler,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
  };
};
