import { Grid } from "@mui/material";
import { GroupForm, Label, NormalText } from "../HivViewCase.styled";
import { Attachments } from "../common/attachments/Attachments";

import { checkValueIsEmpty } from "../HivViewCase.helper";
import { ResearchWeeks } from "./researchWeeks/ResearchWeeks";
import { useHivStore } from "../../../../../../../../store/hooks";

export const QualificationSection = () => {
  const caseStore = useHivStore();
  const { case: caseElement } = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5}>
      <Grid item xs={12} mb={3}>
        <GroupForm>KWALIFIKACJA:</GroupForm>
      </Grid>
      <Grid item xs={12}>
        <Label $size={"large"}>Wyniki badań</Label>
      </Grid>

      <Grid item xs={12} mt={4}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={4}>
            <Label $size={"large"}>W 1 DNIU, PRZED PODANIEM LEKÓW:</Label>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Label $mb={0}>MORFOLOGIA KRWI</Label>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              RBC <span className={"unit"}>[mln/µl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.rbc1)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              HGB <span className={"unit"}>[g/dl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.hgb1)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              HCT <span className={"unit"}>[%]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.htc1)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              PLT <span className={"unit"}>[tys/µl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.plt1)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              WBC <span className={"unit"}>[tys/µl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.wbc1)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              MCV <span className={"unit"}>[fl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.mcv1)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              MCH <span className={"unit"}>[pg]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.mch1)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label $mb={0}>
              MCHC <span className={"unit"}>[g/dl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.mchc1)}</NormalText>
          </Grid>
          <Grid item xs={12} mb={2} mt={3}>
            <Label $mb={0}>
              OZNACZENIE HCV RNA METODĄ ILOŚCIOWĄ{" "}
              <span className={"unit"}>[IU/mL, kopii/ml]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.hcvRna1)}</NormalText>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Label $mb={0}>
              OZNACZENIE STĘŻENIA ALBUMINY{" "}
              <span className={"unit"}>[g/dl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.albumin1)}</NormalText>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Label $mb={0}>
              OZNACZENIE AKTYWNOŚCI AIAT <span className={"unit"}>[U/I]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.aiat1)}</NormalText>
          </Grid>
          <Grid item xs={12}>
            <Label>
              OZNACZENIE STĘŻENIA BILIRUBINY{" "}
              <span className={"unit"}>[mg/dl]</span>
            </Label>
            <NormalText>{checkValueIsEmpty(caseElement.bilirubin1)}</NormalText>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={6}>
              <Label $mb={0}>
                CZY PACJENT LECZONY W UPROSZCZONYM SCHEMACIE MONITOROWANIA
                TERAPII?
              </Label>
              <NormalText $capitalize={true}>
                <b>{checkValueIsEmpty(caseElement.simplifiedDiagram)}</b>
              </NormalText>
            </Grid>
            <Grid item xs={6}>
              <Label $mb={0}>CZAS TRWANIA TERAPII?</Label>
              <NormalText>
                <b>{checkValueIsEmpty(caseElement.durationOfTherapy)}</b>
              </NormalText>
            </Grid>
          </Grid>
        </Grid>
        {caseElement.simplifiedDiagram !== "tak" && (
          <>
            {caseElement.durationOfTherapy && (
              <ResearchWeeks
                week={4}
                rbc={caseElement.rbc4}
                hgb={caseElement.hgb4}
                htc={caseElement.htc4}
                plt={caseElement.plt4}
                wbc={caseElement.wbc4}
                mcv={caseElement.mcv4}
                mch={caseElement.mch4}
                mchc={caseElement.mchc4}
                aiat={caseElement.aiat4}
                bilirubin={caseElement.bilirubin4}
                bilirubinMonitoring={caseElement.bilirubinMonitoring4}
              />
            )}

            {caseElement.durationOfTherapy &&
              caseElement.durationOfTherapy !== "8 tygodni" && (
                <ResearchWeeks
                  week={8}
                  rbc={caseElement.rbc8}
                  hgb={caseElement.hgb8}
                  htc={caseElement.htc8}
                  plt={caseElement.plt8}
                  wbc={caseElement.wbc8}
                  mcv={caseElement.mcv8}
                  mch={caseElement.mch8}
                  mchc={caseElement.mchc8}
                  aiat={caseElement.aiat8}
                  bilirubin={caseElement.bilirubin8}
                  bilirubinMonitoring={caseElement.bilirubinMonitoring8}
                />
              )}

            {caseElement.durationOfTherapy &&
              caseElement.durationOfTherapy !== "12 tygodni" &&
              caseElement.durationOfTherapy !== "8 tygodni" && (
                <ResearchWeeks
                  week={12}
                  rbc={caseElement.rbc12}
                  hgb={caseElement.hgb12}
                  htc={caseElement.htc12}
                  plt={caseElement.plt12}
                  wbc={caseElement.wbc12}
                  mcv={caseElement.mcv12}
                  mch={caseElement.mch12}
                  mchc={caseElement.mchc12}
                  aiat={caseElement.aiat12}
                  bilirubin={caseElement.bilirubin12}
                  bilirubinMonitoring={caseElement.bilirubinMonitoring12}
                />
              )}
          </>
        )}

        {caseElement.simplifiedDiagram === "nie" && (
          <Grid item xs={12} mt={4}>
            <Grid container borderRadius={1} bgcolor={"white"} p={2}>
              <Grid item xs={12} mb={4}>
                <Label $size={"large"}>EOT - NA ZAKOŃCZENIE LECZENIA:</Label>
              </Grid>
              <Grid item xs={12} mb={3}>
                <Label>
                  OZNACZENIE HCV RNA <span className={"unit"}>[mg/dl]</span>
                </Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.hcvRnaAfterTreatment)}
                  <br />
                  {caseElement.hcvRnaAfterTreatment === "wykrywalne"
                    ? caseElement.hcvRnaAfterTreatmentDesc
                    : ""}
                </NormalText>
              </Grid>
              <Grid item xs={12} mb={3}>
                <Label>
                  OZNACZENIE AKTYWNOŚCI AIAT{" "}
                  <span className={"unit"}>[U/I]</span>
                </Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.aiatAfterTreatment)}
                </NormalText>
              </Grid>
              <Grid item xs={12} mb={3}>
                <Label>
                  USG JAMY BRZUSZNEJ <span className={"unit"}>[mg/dl]</span>
                </Label>
                <NormalText>
                  {checkValueIsEmpty(
                    caseElement.abdominalUltrasoundAfterTreatment
                  )}
                </NormalText>
              </Grid>
              {caseElement.abdominalUltrasoundAfterTreatmentFiles &&
                caseElement.abdominalUltrasoundAfterTreatmentFiles.length >
                  0 && (
                  <Grid item xs={12}>
                    <Attachments
                      attachments={
                        caseElement.abdominalUltrasoundAfterTreatmentFiles
                      }
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} mt={4}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={12} mb={4}>
              <Label $size={"large"}>
                SVR12 - PO 12 TYGODNIACH OD ZAKOŃCZENIA LECZENIA:
              </Label>
            </Grid>
            <Grid item xs={12} mb={3}>
              <Label>
                OZNACZENIE HCV RNA METODĄ JAKOŚCIOWĄ{" "}
                <span className={"unit"}>[IU/mL]</span>
              </Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.hcvRnaAfterTreatment12)}
              </NormalText>
            </Grid>
            <Grid item xs={12} mb={3}>
              <Label>
                USG JAMY BRZUSZNEJ <span className={"unit"}>[mg/dl]</span>
              </Label>
              <NormalText>
                {checkValueIsEmpty(
                  caseElement.abdominalUltrasoundAfterTreatment12
                )}
              </NormalText>
            </Grid>
            {caseElement.abdominalUltrasoundAfterTreatmentFiles12 &&
              caseElement.abdominalUltrasoundAfterTreatmentFiles12.length >
                0 && (
                <Grid item xs={12}>
                  <Attachments
                    attachments={
                      caseElement.abdominalUltrasoundAfterTreatmentFiles12
                    }
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
