import theme from "../../../../theme/theme";
import  { FC } from "react";
import { observer } from "mobx-react-lite";
import { Grid } from '@mui/material';

import { Input } from '../../ui/input/Input';
import { ErrorMessage } from '../../ui/errorMessage/ErrorMesage';
import { FormButtons } from '../../ui/formButton/FormButtons';
import { IAreaForm } from '../../../../interfaces/admin/areas/areaForm';
import { MultipleSelect } from '../../ui/multipleSelect/MultipleSelect';
import { useAreaForm } from './useAreaForm';
import { Container, Label } from "./AreaForm.styled";

interface IProps {
  isEdit?: boolean;
  link: string;
  areaForm?: IAreaForm | null;
  actionHandler: (areaForm: IAreaForm) => Promise<string | null>;
  handleNo?: () => void;
}

export const AreaForm: FC<IProps> = observer(({isEdit, link, areaForm, actionHandler, handleNo}) => {
  const {
    formik,
    isButtonActive,
    confirmForm,
    message, 
    handleChange,
    selectFormsOptions,
    selectedForms,
  } = useAreaForm(actionHandler, areaForm)

  return (
    <Container>
        <Input color={theme.colors.grey1}
               colorLabel={theme.colors.grey1}
               borderColor={theme.colors.grey5}
               placeholderColor={theme.colors.grey4}
               background={'white'}
               isRequired={true}
               label={'Nazwa obszaru terapeutycznego'}
               name={'fullTitle'}
               isUpperCase={true}
               type={'text'}
               value={formik.values.fullTitle}
               onChange={formik.handleChange}
               placeholder={'Wpisz'}
               isError={formik.touched.fullTitle && Boolean(formik.errors.fullTitle)}/>  
        <Input color={theme.colors.grey1}
               colorLabel={theme.colors.grey1}
               borderColor={theme.colors.grey5}
               placeholderColor={theme.colors.grey4}
               background={'white'}
               isRequired={true}
               label={'Nazwa skrócona'}
               name={'shortTitle'}
               isUpperCase={true}
               type={'text'}
               value={formik.values.shortTitle}
               onChange={formik.handleChange}
               placeholder={'Wpisz'}
               isError={formik.touched.shortTitle && Boolean(formik.errors.shortTitle)}/>  
         <Grid>
          <Label>
            Formularze<span>*</span>
          </Label>
          <MultipleSelect
            isError={formik.submitCount > 0 && selectedForms.length === 0}
            selectedValues={selectedForms}
            elementName={'formTypes'}
            options={selectFormsOptions}
            handleChange={handleChange}
            subtitle={'Wybierz'}
          />
        </Grid>             
      {formik.submitCount > 0 &&
        <ErrorMessage errors={formik.errors} errorServer={false} message={message}/>
      }
      <FormButtons isButtonActive={isButtonActive} confirmForm={confirmForm}
                   link={link} onClickCancel={handleNo}
                   buttonText={isEdit ? 'zapisz' : 'zapisz i dodaj +'}/>
    </Container>
  )
});
