import React from "react";
import { FormikErrors } from "formik";
import { observer } from "mobx-react";

// hooks
import { useStage02ViewModel } from "./Stage02.vm";

// components
import { Divider, Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";

// helpers, types, styles
import { treatmentOptions, vasOptions } from "./Stage02.helper";
import { IDrugs } from "./type";
import { ButtonAddDrug } from "./Stage02.styled";
import theme from "../../../../../../../../../theme/theme";
import { RadioForm } from "../../../../../stages/firstStage/FirstStage.styled";

//icons
import { ReactComponent as Plus } from "../../../../../../../../../assets/icon/abbviePlus.svg";
import { ReactComponent as Bin } from "../../../../../../../../../assets/icon/abbvieBin.svg";

export const Stage02 = observer(() => {
  const {formik, onSubmitHandler, setOption, addDrug, deleteDrug} = useStage02ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
      onSubmit={onSubmitHandler}
    >
      <Grid item xs={12}>
        <DateInput
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", 'month', 'day']}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data rozpoznania</>}
          isUpperCase={true}
          name={"diagnosisDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.diagnosisDate}
          onClose={() => {
            formik.setFieldTouched("diagnosisDate", true);
            formik.validateField("diagnosisDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("diagnosisDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("diagnosisDate", true);
            setTimeout(() => {
              formik.validateField("diagnosisDate");
            }, 0);
          }}
          isError={
            formik.touched.diagnosisDate &&
            Boolean(formik.errors.diagnosisDate)
          }
        />
      </Grid>
      <Grid container display={'flex'} gap={'20px'} mb={2} mt={2}>
        <Grid
          item
          pb={2}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Wynik das 28"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.das28Result && Boolean(formik.errors.das28Result)}
              id={"das28Result"}
              name={"das28Result"}
              value={"crp"}
              checked={formik.values.das28Result === "crp"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.das28Result && Boolean(formik.errors.das28Result)}
              id={"das28Result2"}
              name={"das28Result"}
              value={"ob"}
              checked={formik.values.das28Result === "ob"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid>
          <Input
            isRequired={true}
            label={"Wynik"}
            isUpperCase={true}
            name={"result"}
            type={"number"}
            maxWidth={'163px'}
            colorLabel={theme.colors.grey1}
            value={formik.values.result || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.result &&
              Boolean(formik.errors.result)
            }
          />
        </Grid>
        <Grid item
              justifyContent={"space-between"}
              pb={2}
              display={"flex"}
              flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Wynik skali"}
          />
          <Typography variant="caption" color={theme.colors.grey1}>
            &lt;2,6 - remisja <br/>
            &le;3,2 – aktywność mała <br/>
            &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
            &gt;5,1 – aktywność duża
          </Typography>

        </Grid>
      </Grid>
      <Grid container display={'flex'} gap={'20px'} mb={2} mt={2}>
        <Grid item xs={2}>
          <SingleSelect
            isRequired={true}
            defaultValue={'Wybierz'}
            isError={formik.touched.vasResult && Boolean(formik.errors.vasResult)}
            label={'Wynik Vas'}
            handleChange={(value) => setOption(value, 'vasResult')}
            selectedValue={formik.values.vasResult ?? ''}
            elementName={'vasResult'}
            options={vasOptions}/>
        </Grid>
        {formik.values.das28Result === 'crp' && <Grid item xs={2}>
          <Input
            isRequired={true}
            label={"Wynik OB"}
            isUpperCase={true}
            name={"obResult"}
            type={"number"}
            maxWidth={'163px'}
            colorLabel={theme.colors.grey1}
            value={formik.values.obResult || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.obResult &&
              Boolean(formik.errors.obResult)
            }
          />
        </Grid>}
        {formik.values.das28Result === 'ob' && <Grid>
          <Input
            isRequired={true}
            label={"Wynik CRP"}
            isUpperCase={true}
            name={"crpResult"}
            type={"number"}
            maxWidth={'163px'}
            colorLabel={theme.colors.grey1}
            value={formik.values.crpResult || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.crpResult &&
              Boolean(formik.errors.crpResult)
            }
          />
        </Grid>}
      </Grid>
      <Grid container display={'flex'} gap={'20px'} mb={2} mt={2}>
        <Grid>
          <Input
            isRequired={true}
            label={"Liczba bolesnych stawów"}
            isUpperCase={true}
            name={"tenderJointsCount"}
            type={"number"}
            maxWidth={'163px'}
            colorLabel={theme.colors.grey1}
            value={formik.values.tenderJointsCount || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.tenderJointsCount &&
              Boolean(formik.errors.tenderJointsCount)
            }
          />
        </Grid>
        <Grid>
          <Input
            isRequired={true}
            label={"Liczba obrzękniętych stawów"}
            isUpperCase={true}
            name={"swollenJointsCount"}
            type={"number"}
            maxWidth={'163px'}
            colorLabel={theme.colors.grey1}
            value={formik.values.swollenJointsCount || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.swollenJointsCount &&
              Boolean(formik.errors.swollenJointsCount)
            }
          />
        </Grid>
      </Grid>
      <Divider style={{width: '100%'}} variant={'fullWidth'} sx={{height: '2px', margin: '0 0 16px 0'}}/>
      {formik.values.drugs.map((drug, index) => (
        <Grid key={index} item xs={12}>
          <Grid item xs={12} mt={2} justifyContent={'space-between'} display={'flex'}>
            <Typography variant={'h6'} color={theme.colors.grey1} textTransform={'uppercase'}
                        fontWeight={700}>Leczenie {index + 1}</Typography>
            {formik.values.drugs.length !== 1 &&
              <ButtonAddDrug onClick={() => deleteDrug(index)}>Usuń leczenie<Bin/></ButtonAddDrug>}
          </Grid>
          <Grid item xs={12} mt={1}>
            <SingleSelect
              isRequired={true}
              defaultValue={'Wybierz'}
              isError={
                Array.isArray(formik.touched.drugs) &&
                formik.touched.drugs[index]?.treatment &&
                Array.isArray(formik.errors.drugs) &&
                Boolean((formik.errors.drugs[index] as FormikErrors<IDrugs>)?.treatment)
              }
              label={'Leczenie'}
              handleChange={(value) => setOption(value, 'treatment', index)}
              selectedValue={formik.values.drugs[index]?.treatment || ''}
              elementName={`drugs[${index}].treatment`}
              options={treatmentOptions}/>
          </Grid>
          <Grid
            item xs={12}
            justifyContent={"space-between"}
            pb={2}
            display={"flex"}
            flexDirection={"column"}
          >
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Glikokortykosteroidy (GKS)"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={
                  Array.isArray(formik.touched.drugs) &&
                  formik.touched.drugs[index]?.gks &&
                  Array.isArray(formik.errors.drugs) &&
                  Boolean((formik.errors.drugs[index] as FormikErrors<IDrugs>)?.gks)
                }
                id={`gks1-${index}`}
                name={`drugs[${index}].gks`}
                value={"tak"}
                checked={formik.values.drugs[index].gks === "tak"}
                onChange={(e) => formik.setFieldValue(`drugs[${index}].gks`, e.target.value)}
              />
              <RadioBox
                isError={
                  Array.isArray(formik.touched.drugs) &&
                  formik.touched.drugs[index]?.gks &&
                  Array.isArray(formik.errors.drugs) &&
                  Boolean((formik.errors.drugs[index] as FormikErrors<IDrugs>)?.gks)
                }
                id={`gks2-${index}`}
                name={`drugs[${index}].gks`}
                value={"nie"}
                checked={formik.values.drugs[index].gks === "nie"}
                onChange={(e) => formik.setFieldValue(`drugs[${index}].gks`, e.target.value)}
              />
            </RadioForm>
          </Grid>
          <Grid container display={'flex'} gap={'20px'} alignItems={'flex-end'}>
            <Grid>
              <Input
                isRequired={true}
                label={"Dawka"}
                isUpperCase={true}
                name={`drugs[${index}].dose`}
                type={"number"}
                maxWidth={'163px'}
                colorLabel={theme.colors.grey1}
                value={formik.values.drugs?.[index]?.dose || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.drugs?.[index]?.dose &&
                  Boolean((formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.dose)
                }
              />
            </Grid>
            <Grid pb={2}>
              <RadioForm>
                <RadioBox
                  id={`unit${index}1`}
                  name={`drugs[${index}].unit`}
                  value={"g/m"}
                  isUpperCase={false}
                  checked={drug.unit === "g/m"}
                  onChange={(e) => formik.setFieldValue(`drugs[${index}].unit`, e.target.value)}
                  isError={
                    formik.touched.drugs?.[index]?.unit &&
                    Boolean((formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.unit)
                  }
                />
                <RadioBox
                  id={`unit${index}2`}
                  name={`drugs[${index}].unit`}
                  value={"mg/kg m.c."}
                  isUpperCase={false}
                  checked={drug.unit === "mg/kg m.c."}
                  onChange={(e) => formik.setFieldValue(`drugs[${index}].unit`, e.target.value)}
                  isError={
                    formik.touched.drugs?.[index]?.unit &&
                    Boolean((formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.unit)
                  }
                />
                <RadioBox
                  id={`unit${index}3`}
                  name={`drugs[${index}].unit`}
                  value={"Inna jednostka"}
                  isUpperCase={false}
                  checked={drug.unit === "Inna jednostka"}
                  onChange={(e) => formik.setFieldValue(`drugs[${index}].unit`, e.target.value)}
                  isError={
                    formik.touched.drugs?.[index]?.unit &&
                    Boolean((formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.unit)
                  }
                />
              </RadioForm>
            </Grid>
            {formik.values.drugs[index].unit === "Inna jednostka" && <Grid>
              <Input
                isRequired={true}
                label={"Inna jednostka"}
                isUpperCase={true}
                name={`drugs[${index}].otherUnit`}
                type={"text"}
                maxWidth={'163px'}
                colorLabel={theme.colors.grey1}
                value={formik.values.drugs?.[index]?.otherUnit || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz"}
                isError={
                  formik.touched.drugs?.[index]?.otherUnit &&
                  Boolean((formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.otherUnit)
                }
              />
            </Grid>}
          </Grid>
          <Grid item xs={12}>
            <Input
              before={
                <Label
                  isUpperCase={false}
                  style={{margin: "8px 0 0"}}
                  color={theme.colors.orange}
                  size={"superSmall"}
                  label={"liczba tygodni"}
                />
              }
              maxWidth={"72px"}
              isRequired={true}
              label={"Zakres czasu przyjmowania"}
              isUpperCase={true}
              name={`drugs[${index}].doseRange`}
              type={"number"}
              colorLabel={theme.colors.grey1}
              value={formik.values.drugs?.[index]?.doseRange || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.drugs?.[index]?.doseRange &&
                Boolean((formik.errors.drugs?.[index] as FormikErrors<IDrugs>)?.doseRange)
              }
            />
          </Grid>
          <Divider style={{width: '100%'}} variant={'fullWidth'} sx={{height: '2px', margin: '0 0 16px 0'}}/>
        </Grid>
      ))}
      <Grid display={'flex'} item xs={12} justifyContent={'center'}><ButtonAddDrug onClick={addDrug}>Dodaj
        leczenie<Plus/></ButtonAddDrug></Grid>
    </Grid>
  );
});
