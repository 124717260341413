import * as yup from "yup";

// hooks
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import {
  useCaseSettingsStore,
  useRzsStore,
} from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";

// types, helpers
import { TRzsStage01 } from "./type";
import { comorbiditiesOptions } from "./Stage01.helper";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";

const validationSchema = yup.object().shape({
  declaration: yup.boolean().oneOf([true]).required(),
  title: yup.string().required(),
  initials: yup.string().max(3).required(),
  gender: yup.string().required(),
  age: yup.number().required(),
  weightKg: yup.number().required(),
  heightCm: yup.number().required(),
  bmi: yup.number().required(),
  durationRzs: yup.number().nullable(),
  comorbidities: yup.array().min(1).required(),
  comorbiditiesOther: yup
    .string()
    .nullable()
    .when("comorbidities", {
      is: (value: string[]) => value?.includes("Inne"),
      then: (schema) => schema.required(),
    }),
  htz: yup.string().nullable(),
  contraception: yup.string().nullable(),
  smoking: yup.string().required(),
  packYears: yup.number().nullable(),
  psoriasis: yup.string().nullable(),
  accp: yup.string().nullable(),
});

const initialValues: TRzsStage01 = {
  area: 0,
  form: 0,
  declaration: false,
  title: "",
  initials: "",
  gender: "",
  age: null,
  weightKg: null,
  heightCm: null,
  bmi: null,
  durationRzs: null,
  comorbidities: [],
  comorbiditiesOther: null,
  htz: "",
  contraception: "",
  smoking: "",
  packYears: null,
  psoriasis: "",
  accp: "",
};

export const useStage01ViewModel = () => {
  // params and location
  const params = useParams();
  const location = useLocation();
  const {area, form, caseId} = params;
  const navigate = useNavigate();

  // define is create or edit case page
  const isCreateCasePage = !caseId && location.pathname?.includes("add");

  // rzs store
  const {firstStage} = useRzsStore();

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {
    userPrescribedAreas,
    isNextButtonClicked,
    isPrevButtonClicked,
    currentStage,
  } = caseSettingsStore;

  // define API calls
  const caseApi = useCaseApi();

  const areaId = userPrescribedAreas?.find(
    (areaObj) => areaObj.name === area
  )?.id;

  const formId = userPrescribedAreas
    ?.find((areaObj) => areaObj.name === area)
    ?.forms.find((formObj) => formObj.name === form)?.id;

  const valuesObj = isCreateCasePage
    ? JSON.parse(JSON.stringify(initialValues))
    : JSON.parse(JSON.stringify(firstStage))

  const formik = useFormik<TRzsStage01>({
    initialValues: {
      ...valuesObj,
      area: areaId || 0,
      form: formId || 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        psoriasis: values.psoriasis || null,
        htz: values.htz || null,
        accp: values.accp || null,
        contraception: values.contraception || null,
        packYears:
          typeof values.packYears === "number"
            ? values.packYears
            : values.packYears || null,
        durationRzs:
          typeof values.durationRzs === "number"
            ? values.durationRzs
            : values.durationRzs || null,
        comorbiditiesOther: !values.comorbidities?.includes("Inne")
          ? null
          : values.comorbiditiesOther,
      };

      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );

      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (isCreateCasePage) {
        const createNewCaseResponse = await caseApi.createNewCase(
          newValues,
          area as EnAreaNames,
          form as EnFormNames
        );

        if (createNewCaseResponse) {
          caseSettingsStore.increaseCurrentStage();
          navigate(`/case/edit/${area}/${form}/${createNewCaseResponse}`);
        } else {
          throw new Error(
            "Formik submit first stage error, some rules are broken"
          );
        }
      } else {
        if (!caseId) throw new Error("Case ID is missing");
        if (!currentStage) throw new Error("Current stage is missing");

        await caseApi.updateCase({
          area: area as EnAreaNames,
          form: form as EnFormNames,
          caseId,
          value: newValues,
          stage: currentStage,
        });
      }
    },
  });

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false
  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await formik.submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  const {setFieldValue} = formik;

  useEffect(() => {
    if (formik.values.weightKg && formik.values.heightCm) {
      const {weightKg, heightCm} = formik.values;
      const bmi =
        weightKg > 0 && heightCm > 0
          ? Math.floor(weightKg / (heightCm * 0.01) ** 2)
          : 0;
      setFieldValue("bmi", bmi);
    }
  }, [
    formik.values.weightKg,
    formik.values.heightCm,
    formik.values,
    setFieldValue,
  ]);

  return {
    formik,
    comorbiditiesOptions,
  };
};
