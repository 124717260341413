import React, { useEffect, useState } from "react";

import { createTableRows } from "../../../shared/customTables/utils/createTableRows";
import { ActionButtonStyled } from "../../../shared/customTables/button/ActionButton.styled";
import { useModal } from "../../../shared/modal/useModal.vm";
import { IUserForm } from "../../../../interfaces/admin/user/userForm";
import { IHeadCell } from "../../../../interfaces/common/table";
import { RoleFunction } from "../../../../constants/projectUserRoles";
import { ModalType } from "../../../../constants/modalType";
import { checkPermission } from "../../../../utils/checkPermission";
import { checkIsActive } from "../../../shared/customTables/utils/actionsHelper";
import { useUsersApi } from "../../../../apiHooks";
import {
  useAreasStore,
  useAuthStore,
  useUsersStore,
} from "../../../../store/hooks";
import theme from "../../../../theme/theme";
import { ReactComponent as DeleteIcon } from "../../../../assets/icon/delete.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icon/edit.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/icon/message.svg";
import { IAreas } from "../../../../interfaces/common/areas";
import { TableCellData } from "../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled";
import {
  filterUsers,
  generateAreaOptions,
} from "../../../../utils/filtredUsers";
import {
  RoleSelectContainer,
  TopContainer,
} from "../othersAdmins/OthersAdmins.styled";
import { Select } from "../../../shared/ui/select/Select";

export const usePublishingUser = () => {
  const { roleFunction } = useAuthStore();
  const areasStore = useAreasStore();
  const { areas } = areasStore;
  const [selectAreaValue, setSelectedAreaValue] = useState(-1);
  const [actionId, setActionId] = useState<number | null>(null);
  const {
    getPublishingUsers,
    resendActivateEmail,
    deleteUser,
    editUser,
    getUser,
  } = useUsersApi();
  const usersStore = useUsersStore();
  const { publishingUsers, userEdit, isPublishingUsersLoaded } = usersStore;
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();

  useEffect(() => {
    if (!isPublishingUsersLoaded) {
      if (publishingUsers === null) {
        void getPublishingUsers();
      } else {
        void getPublishingUsers(false);
      }
    }
  }, [isPublishingUsersLoaded, publishingUsers, getPublishingUsers]);

  useEffect(() => {
    return () => usersStore.resetPublishingUsers();
  }, [usersStore]);

  const deleteHandler = (id: number | string) => {
    handleModal(ModalType.DELETE_MODAL);
    setActionId(+id);
  };

  const editModalHandler = async (id: number | string) => {
    const user = await getUser(+id);
    if (user) {
      handleModal(ModalType.EDIT_MODAL);
    }
  };

  const editHandler = async (userForm: IUserForm) => {
    const isEdit = await editUser(userForm);
    if (isEdit) {
      await handleModal(ModalType.EDIT_CONFIRMATION_MODAL);
      setActionId(null);
      usersStore.clearUserEdit();
      await getPublishingUsers();
    }
    return null;
  };

  const deleteHandlerYes = async () => {
    if (publishingUsers && actionId) {
      const isDelete = await deleteUser(actionId);
      if (isDelete) {
        await getPublishingUsers();
        setActionId(null);
        handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
      }
    }
  };

  const sendLinkHandler = (id: number | string) => {
    if (publishingUsers) {
      const user = publishingUsers.find((user) => user.id === +id);
      if (user && !user.isActive) {
        void resendActivateEmail(+id);
      }
    }
  };

  const generateSendButton = (id: number) => {
    if (checkIsActive(id, publishingUsers ?? [])) {
      return (
        <ActionButtonStyled>
          Wyślij link <MessageIcon />
        </ActionButtonStyled>
      );
    }
    return <div>Aktywny</div>;
  };

  const generateBackground = (id: number) => {
    if (checkIsActive(id, publishingUsers ?? [])) {
      return theme.colors.green;
    }
    return theme.colors.grey4;
  };

  const generateIsDisabled = (id: number) => {
    return !checkIsActive(id, publishingUsers ?? []) ?? true;
  };

  const generateActions = () => {
    if (
      roleFunction &&
      checkPermission(roleFunction, [RoleFunction.ROLE_ADMIN])
    ) {
      return [
        {
          key: "send",
          actionHandler: sendLinkHandler,
          background: generateBackground,
          actionElement: generateSendButton,
          width: 138,
          isDisabled: generateIsDisabled,
        },
        {
          key: "edit",
          actionHandler: editModalHandler,
          background: theme.colors.abbvie,
          actionElement: <EditIcon />,
          width: 52,
        },
        {
          key: "delete",
          actionHandler: deleteHandler,
          background: theme.colors.blue2,
          actionElement: <DeleteIcon />,
          width: 52,
        },
      ];
    }
    return [];
  };

  const cellsSwitcher = (
    keyName: string,
    value: string | IAreas[],
    onClick?: () => void
  ) => {
    switch (keyName) {
      case "id":
        return null;
      case "areas":
        return (
          <TableCellData>
            {(value as IAreas[]).map((area) => area.title).join(", ")}
          </TableCellData>
        );
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const headCells: IHeadCell[] = [
    { id: "fullName", numeric: false, label: "Imię i nazwisko" },
    { id: "areas", numeric: false, label: "Obszar terapeutyczny" },
    { id: "phone", numeric: false, label: "Numer kontaktowy" },
    { id: "email", numeric: false, label: "Email" },
  ];

  const selectAreaOptions = generateAreaOptions(areas);

  const addedContent = (
    <TopContainer>
      <RoleSelectContainer>
        <Select
          selectValue={selectAreaValue}
          elementName={"area-filter"}
          setValue={setSelectedAreaValue}
          background={"white"}
          optionsArray={selectAreaOptions}
        />
      </RoleSelectContainer>
    </TopContainer>
  );

  const filteredData = filterUsers(publishingUsers, selectAreaValue);

  const tableDataRows = createTableRows(filteredData, headCells, false);

  return {
    publishingUsersListIsNotNull: publishingUsers !== null,
    count: publishingUsers ? publishingUsers.length : 0,
    cellsSwitcher,
    userEdit,
    headCells,
    generateActions,
    tableDataRows,
    modalType,
    isModalOpened,
    editHandler,
    deleteHandlerYes,
    closeModal,
    addedContent,
  };
};
