import { observer } from 'mobx-react';
import { useParams } from 'react-router';

// types 
import { EnFormNames } from '../../../../../store/caseSettingsStore';

// components
import { HivViewCase } from '../forms/virusology/hiv/HivViewCase';
import { RzsViewCase } from '../forms/rheumatology/rzs/RzsViewCase';

export const ViewCaseCreator = observer(() => {
  const { form} = useParams();
  const formConvert = form as EnFormNames

  switch(formConvert){
    case EnFormNames.hiv: return <HivViewCase />
    case EnFormNames.rzs: return <RzsViewCase/>
  }

  return null;
});
  