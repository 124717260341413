import styled from 'styled-components';
import theme from '../../../../theme/theme';

export const FormsContainer = styled.div`
    width: 100%;
    .search-bar-container{
        justify-content: start;
    }
`
export const TopContentContainer = styled.div`
    padding-bottom: 30px;
`

export const Title = styled.h1`
    height: 36px;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${theme.colors.abbvie};
    position: relative;
    top: -95px;
    left: -270px;
`

