import React from "react";
import { observer } from "mobx-react";
import { FormikErrors } from "formik";

// hooks
import { useStage03ViewModel } from "./Stage03.vm";

// components
import { Divider, Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";

// lib, helpers, styles
import theme from "../../../../../../../../../theme/theme";
import {
  combinationsOptions,
  jakOptions,
  tnfOptions,
  variantOptions,
  vasOptions,
} from "./Stage03.helper";
import { ICombinations, ITreatmentLines } from "./type";
import { ButtonAddDrug } from "../stage02/Stage02.styled";
import { RadioForm } from "../../../../../stages/firstStage/FirstStage.styled";

// icons
import { ReactComponent as Bin } from "../../../../../../../../../assets/icon/abbvieBin.svg";
import { ReactComponent as Plus } from "../../../../../../../../../assets/icon/abbviePlus.svg";

export const Stage03 = observer(() => {
  const {
    formik,
    onSubmitHandler,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    setCombinationOption,
    addOtherCombination,
  } = useStage03ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
      onSubmit={onSubmitHandler}
    >
      <Grid item xs={12} mb={2}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Moment włączenia do programu lekowego
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateInput
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data wejścia do programu lekowego</>}
          isUpperCase={true}
          name={"programEntryDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.programEntryDate}
          onClose={() => {
            formik.setFieldTouched("programEntryDate", true);
            formik.validateField("programEntryDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("programEntryDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("programEntryDate", true);
            setTimeout(() => {
              formik.validateField("programEntryDate");
            }, 0);
          }}
          isError={
            formik.touched.programEntryDate &&
            Boolean(formik.errors.programEntryDate)
          }
        />
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Wynik das 28"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.das28ResultMoi &&
                Boolean(formik.errors.das28ResultMoi)
              }
              id={"das28ResultMoi"}
              name={"das28ResultMoi"}
              value={"crp"}
              checked={formik.values.das28ResultMoi === "crp"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.das28ResultMoi &&
                Boolean(formik.errors.das28ResultMoi)
              }
              id={"das28ResultMoi2"}
              name={"das28ResultMoi"}
              value={"ob"}
              checked={formik.values.das28ResultMoi === "ob"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid>
          <Input
            isRequired={true}
            label={"Wynik"}
            isUpperCase={true}
            name={"resultMoi"}
            type={"number"}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.resultMoi || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.resultMoi && Boolean(formik.errors.resultMoi)
            }
          />
        </Grid>
        <Grid
          item
          justifyContent={"space-between"}
          pb={2}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Wynik skali"}
          />
          <Typography variant="caption" color={theme.colors.grey1}>
            &lt;2,6 - remisja <br />
            &le;3,2 – aktywność mała <br />
            &gt;3,2 i &le;5,1 – aktywność umiarkowana <br />
            &gt;5,1 – aktywność duża
          </Typography>
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid item xs={2}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.vasResultMoi && Boolean(formik.errors.vasResultMoi)
            }
            label={"Wynik Vas"}
            handleChange={(value) => setOption(value, "vasResultMoi")}
            selectedValue={formik.values.vasResultMoi ?? ""}
            elementName={"vasResultMoi"}
            options={vasOptions}
          />
        </Grid>
        {formik.values.das28ResultMoi === "crp" && (
          <Grid item xs={2}>
            <Input
              isRequired={true}
              label={"Wynik OB"}
              isUpperCase={true}
              name={"obResultMoi"}
              type={"number"}
              maxWidth={"163px"}
              colorLabel={theme.colors.grey1}
              value={formik.values.obResultMoi || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.obResultMoi && Boolean(formik.errors.obResultMoi)
              }
            />
          </Grid>
        )}
        {formik.values.das28ResultMoi === "ob" && (
          <Grid>
            <Input
              isRequired={true}
              label={"Wynik CRP"}
              isUpperCase={true}
              name={"crpResultMoi"}
              type={"number"}
              maxWidth={"163px"}
              colorLabel={theme.colors.grey1}
              value={formik.values.crpResultMoi || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.crpResultMoi &&
                Boolean(formik.errors.crpResultMoi)
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid>
          <Input
            isRequired={true}
            label={"Liczba bolesnych stawów"}
            isUpperCase={true}
            name={"tenderJointsCountMoi"}
            type={"number"}
            maxWidth={"72px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.tenderJointsCountMoi || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.tenderJointsCountMoi &&
              Boolean(formik.errors.tenderJointsCountMoi)
            }
          />
        </Grid>
        <Grid>
          <Input
            isRequired={true}
            label={"Liczba obrzękniętych stawów"}
            isUpperCase={true}
            name={"swollenJointsCountMoi"}
            type={"number"}
            maxWidth={"72px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.swollenJointsCountMoi || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.swollenJointsCountMoi &&
              Boolean(formik.errors.swollenJointsCountMoi)
            }
          />
        </Grid>
      </Grid>
      <Divider
        style={{ width: "100%" }}
        variant={"fullWidth"}
        sx={{ height: "2px", margin: "0 0 16px 0" }}
      />
      <Grid
        item
        xs={12}
        mb={2}
        mt={2}
        display={"flex"}
        justifyContent={"center"}
      >
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Linie leczenia
        </Typography>
      </Grid>
      <Grid container>
        {formik.values.treatmentLines?.map((line, index) => (
          <Grid
            key={index}
            item
            xs={12}
            mb={4}
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "0 16px",
              backgroundColor: "rgba(255, 255, 255, 0.70)",
            }}
          >
            <Grid
              item
              xs={12}
              mt={2}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <Typography
                variant={"h6"}
                color={theme.colors.grey1}
                textTransform={"uppercase"}
                fontWeight={700}
              >
                Linia leczenia {index + 1}
              </Typography>
              {formik.values.treatmentLines.length !== 1 && (
                <ButtonAddDrug onClick={() => deleteTreatment(index)}>
                  Usuń linię leczenia
                  <Bin />
                </ButtonAddDrug>
              )}
            </Grid>
            <Grid item xs={12} mt={1}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  Array.isArray(formik.touched.treatmentLines) &&
                  formik.touched.treatmentLines[index]?.variants &&
                  Array.isArray(formik.errors.treatmentLines) &&
                  Boolean(
                    (
                      formik.errors.treatmentLines[
                        index
                      ] as FormikErrors<ITreatmentLines>
                    )?.variants
                  )
                }
                label={"Wybierz wariant"}
                handleChange={(value) => setOption(value, "variants", index)}
                selectedValue={
                  formik.values.treatmentLines[index]?.variants || ""
                }
                elementName={`treatmentLines[${index}].variants`}
                options={variantOptions}
              />
            </Grid>
            {formik.values.treatmentLines[index]?.variants === "Inne" && (
              <>
                <Grid>
                  <Input
                    isRequired={true}
                    label={"Inne rodzaj"}
                    isUpperCase={true}
                    name={`treatmentLines[${index}].otherVariantType`}
                    type={"text"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.treatmentLines?.[index]?.otherVariantType ||
                      ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.treatmentLines?.[index]
                        ?.otherVariantType &&
                      Boolean(
                        (
                          formik.errors.treatmentLines?.[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.otherVariantType
                      )
                    }
                  />
                </Grid>
                <Grid>
                  <Input
                    isRequired={true}
                    label={"Inne dawka"}
                    isUpperCase={true}
                    name={`treatmentLines[${index}].dose`}
                    type={"text"}
                    colorLabel={theme.colors.grey1}
                    value={formik.values.treatmentLines?.[index]?.dose || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.treatmentLines?.[index]?.dose &&
                      Boolean(
                        (
                          formik.errors.treatmentLines?.[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.dose
                      )
                    }
                  />
                </Grid>
              </>
            )}
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory TNF" && (
              <>
                <Grid item xs={12} mt={1}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.tnfInhibitorType &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.tnfInhibitorType
                      )
                    }
                    label={"Rodzaj inhibitora TNF"}
                    handleChange={(value) =>
                      setOption(value, "tnfInhibitorType", index)
                    }
                    selectedValue={
                      formik.values.treatmentLines[index]?.tnfInhibitorType ||
                      ""
                    }
                    elementName={`treatmentLines[${index}].tnfInhibitorType`}
                    options={tnfOptions}
                  />
                </Grid>
              </>
            )}
            {formik.values.treatmentLines[index]?.tnfInhibitorType ===
              "Adalimumab inne" && (
              <>
                <Grid>
                  <Input
                    isRequired={true}
                    label={"adalimumab inne rodzaj"}
                    isUpperCase={true}
                    name={`treatmentLines[${index}].adalimumabOtherVariant`}
                    type={"text"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.treatmentLines?.[index]
                        ?.adalimumabOtherVariant || ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.treatmentLines?.[index]
                        ?.adalimumabOtherVariant &&
                      Boolean(
                        (
                          formik.errors.treatmentLines?.[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.adalimumabOtherVariant
                      )
                    }
                  />
                </Grid>
                <Grid>
                  <Input
                    isRequired={true}
                    label={"adalimumab inne dawka"}
                    before={
                      <Label
                        isUpperCase={false}
                        style={{ margin: "8px 0 0" }}
                        color={theme.colors.orange}
                        size={"superSmall"}
                        label={"mg/tydzień"}
                      />
                    }
                    isUpperCase={true}
                    name={`treatmentLines[${index}].adalimumabOtherDose`}
                    type={"text"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.treatmentLines?.[index]
                        ?.adalimumabOtherDose || ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.treatmentLines?.[index]
                        ?.adalimumabOtherDose &&
                      Boolean(
                        (
                          formik.errors.treatmentLines?.[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.adalimumabOtherDose
                      )
                    }
                  />
                </Grid>
              </>
            )}
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory IL-6" && (
              <Grid>
                <Label
                  isUpperCase={true}
                  style={{ margin: "8px 0 0" }}
                  color={theme.colors.grey1}
                  size={"small"}
                  label={"Rodzaj inhibitora IL-6:"}
                />
                <Label
                  isUpperCase={false}
                  style={{ margin: "0" }}
                  color={theme.colors.orange}
                  size={"superSmall"}
                  label={"Tocilizumab"}
                />
                <Input
                  isRequired={true}
                  label={"Dawka"}
                  isUpperCase={true}
                  name={`treatmentLines[${index}].il6Dose`}
                  type={"text"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.treatmentLines?.[index]?.il6Dose || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    formik.touched.treatmentLines?.[index]?.il6Dose &&
                    Boolean(
                      (
                        formik.errors.treatmentLines?.[
                          index
                        ] as FormikErrors<ITreatmentLines>
                      )?.il6Dose
                    )
                  }
                />
              </Grid>
            )}
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory JAK" && (
              <>
                <Grid item xs={12} mt={1}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.jakInhibitorType &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.jakInhibitorType
                      )
                    }
                    label={"Rodzaj inhibitora JAK"}
                    handleChange={(value) =>
                      setOption(value, "jakInhibitorType", index)
                    }
                    selectedValue={
                      formik.values.treatmentLines[index]?.jakInhibitorType ||
                      ""
                    }
                    elementName={`treatmentLines[${index}].jakInhibitorType`}
                    options={jakOptions}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              pb={2}
              display={"flex"}
              flexDirection={"column"}
            >
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Glikokortykosteroidy (GKS)"}
                isRequired={true}
              />
              <RadioForm>
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.monotherapy &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                        ] as FormikErrors<ITreatmentLines>
                      )?.monotherapy
                    )
                  }
                  id={`monotherapy1-${index}`}
                  name={`treatmentLines[${index}].monotherapy`}
                  value={"monoterapia"}
                  checked={
                    formik.values.treatmentLines[index].monotherapy ===
                    "monoterapia"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `treatmentLines[${index}].monotherapy`,
                      e.target.value
                    );
                    formik.setFieldValue(
                      `treatmentLines[${index}].combinationWithMedicine`,
                      []
                    );
                  }}
                />
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.monotherapy &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                        ] as FormikErrors<ITreatmentLines>
                      )?.monotherapy
                    )
                  }
                  id={`monotherapy2-${index}`}
                  name={`treatmentLines[${index}].monotherapy`}
                  value={"kombinacja"}
                  checked={
                    formik.values.treatmentLines[index].monotherapy ===
                    "kombinacja"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `treatmentLines[${index}].monotherapy`,
                      e.target.value
                    );
                    addCombination(index);
                  }}
                />
              </RadioForm>
            </Grid>
            {formik.values.treatmentLines &&
              formik.values.treatmentLines.length > 0 &&
              formik.values.treatmentLines[index]?.combinationWithMedicine
                .length > 0 &&
              formik.values.treatmentLines[index]?.combinationWithMedicine?.map(
                (combination, idx) => (
                  <Grid style={{ backgroundColor: "rgba(7, 28, 71, 0.10)" }}>
                    <Grid key={idx} item xs={12} p={2} mb={2}>
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        justifyContent={"space-between"}
                        display={"flex"}
                      >
                        <Typography
                          variant={"h6"}
                          color={theme.colors.grey1}
                          textTransform={"uppercase"}
                          fontWeight={700}
                        >
                          Kombinacja {idx + 1}
                        </Typography>
                        {formik.values.treatmentLines[index]
                          .combinationWithMedicine?.length !== 1 && (
                          <ButtonAddDrug
                            onClick={() => deleteCombination(index, idx)}
                          >
                            Usuń kombinację
                            <Bin />
                          </ButtonAddDrug>
                        )}
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <SingleSelect
                          isRequired={true}
                          defaultValue={"Wybierz"}
                          isError={
                            Array.isArray(formik.touched.treatmentLines) &&
                            Array.isArray(formik.errors.treatmentLines) &&
                            typeof formik.errors.treatmentLines[index] ===
                              "object" &&
                            formik.touched.treatmentLines[index]
                              ?.combinationWithMedicine?.[idx]
                              ?.combinationDrugName &&
                            Boolean(
                              (
                                (
                                  formik.errors.treatmentLines[
                                    index
                                  ] as FormikErrors<ITreatmentLines>
                                )?.combinationWithMedicine?.[
                                  idx
                                ] as FormikErrors<ICombinations>
                              )?.combinationDrugName
                            )
                          }
                          label={"Leczenie"}
                          handleChange={(value) =>
                            setCombinationOption(
                              value,
                              "combinationDrugName",
                              index,
                              idx
                            )
                          }
                          selectedValue={
                            formik.values.treatmentLines[index]
                              ?.combinationWithMedicine[idx]
                              .combinationDrugName || ""
                          }
                          elementName={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDrugName`}
                          options={combinationsOptions}
                        />
                      </Grid>
                      {formik.values.treatmentLines[index]
                        .combinationWithMedicine[idx].combinationDrugName ===
                        "Inne" && (
                        <Grid item xs={12}>
                          <Input
                            isRequired={true}
                            label={"nazwa innego leku*"}
                            isUpperCase={true}
                            name={`treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationDrug`}
                            type={"text"}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.treatmentLines?.[index]
                                ?.combinationWithMedicine[idx]
                                .otherCombinationDrug || ""
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.treatmentLines) &&
                              Array.isArray(formik.errors.treatmentLines) &&
                              typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                              formik.touched.treatmentLines[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.otherCombinationDrug &&
                              Boolean(
                                (
                                  (
                                    formik.errors.treatmentLines[
                                      index
                                    ] as FormikErrors<ITreatmentLines>
                                  )?.combinationWithMedicine?.[
                                    idx
                                  ] as FormikErrors<ICombinations>
                                )?.otherCombinationDrug
                              )
                            }
                          />
                        </Grid>
                      )}
                      <Grid
                        container
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"flex-end"}
                      >
                        <Grid>
                          <Input
                            isRequired={true}
                            label={"Dawka"}
                            isUpperCase={true}
                            name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDose`}
                            type={"number"}
                            maxWidth={"163px"}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.treatmentLines?.[index]
                                ?.combinationWithMedicine[idx]
                                .combinationDose || ""
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.treatmentLines) &&
                              Array.isArray(formik.errors.treatmentLines) &&
                              typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                              formik.touched.treatmentLines[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDose &&
                              Boolean(
                                (
                                  (
                                    formik.errors.treatmentLines[
                                      index
                                    ] as FormikErrors<ITreatmentLines>
                                  )?.combinationWithMedicine?.[
                                    idx
                                  ] as FormikErrors<ICombinations>
                                )?.combinationDose
                              )
                            }
                          />
                        </Grid>
                        <Grid pb={2}>
                          <RadioForm>
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit1`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"g/m"}
                              isUpperCase={false}
                              checked={combination.combinationUnit === "g/m"}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                  "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                      ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit2`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"mg/kg m.c."}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "mg/kg m.c."
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                  "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                      ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit3`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"Inna jednostka"}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "Inna jednostka"
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                  "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                      ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                          </RadioForm>
                        </Grid>
                        {formik.values.treatmentLines[index]
                          .combinationWithMedicine[idx].combinationUnit ===
                          "Inna jednostka" && (
                          <Grid>
                            <Input
                              isRequired={true}
                              label={"Inna jednostka"}
                              isUpperCase={true}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`}
                              type={"text"}
                              maxWidth={"163px"}
                              colorLabel={theme.colors.grey1}
                              value={
                                formik.values.treatmentLines?.[index]
                                  ?.combinationWithMedicine[idx]
                                  .otherCombinationUnit || ""
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              borderColor={theme.colors.grey5}
                              placeholderColor={theme.colors.grey4}
                              background={theme.colors.white}
                              placeholder={"Wpisz"}
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                  "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.otherCombinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                      ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                    ] as FormikErrors<ICombinations>
                                  )?.otherCombinationUnit
                                )
                              }
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Input
                            before={
                              <Label
                                isUpperCase={false}
                                style={{ margin: "8px 0 0" }}
                                color={theme.colors.orange}
                                size={"superSmall"}
                                label={"liczba tygodni"}
                              />
                            }
                            maxWidth={"72px"}
                            isRequired={true}
                            label={"Zakres czasu przyjmowania"}
                            isUpperCase={true}
                            name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDuration`}
                            type={"number"}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.treatmentLines?.[index]
                                ?.combinationWithMedicine[idx]
                                .combinationDuration || ""
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.treatmentLines) &&
                              Array.isArray(formik.errors.treatmentLines) &&
                              typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                              formik.touched.treatmentLines[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDuration &&
                              Boolean(
                                (
                                  (
                                    formik.errors.treatmentLines[
                                      index
                                    ] as FormikErrors<ITreatmentLines>
                                  )?.combinationWithMedicine?.[
                                    idx
                                  ] as FormikErrors<ICombinations>
                                )?.combinationDuration
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}

            {formik.values.treatmentLines[index].monotherapy ===
              "kombinacja" && (
              <Grid
                mt={4}
                mb={2}
                display={"flex"}
                item
                xs={12}
                justifyContent={"center"}
              >
                <ButtonAddDrug onClick={() => addOtherCombination(index)}>
                  Dodaj kombinację z lekiem
                  <Plus />
                </ButtonAddDrug>
              </Grid>
            )}

            <Divider
              style={{ width: "100%" }}
              variant={"fullWidth"}
              sx={{ height: "2px", margin: "0 0 16px 0" }}
            />

            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                <Label
                  size={"small"}
                  isUpperCase={true}
                  color={theme.colors.grey1}
                  label={"Wynik das 28"}
                  isRequired={true}
                />
                <RadioForm>
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.das28FollowupResult &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.das28FollowupResult
                      )
                    }
                    id={`das28FollowupResult1-${index}`}
                    name={`treatmentLines[${index}].das28FollowupResult`}
                    value={"crp"}
                    checked={
                      formik.values.treatmentLines[index]
                        .das28FollowupResult === "crp"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].das28FollowupResult`,
                        e.target.value
                      );
                    }}
                  />
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.das28FollowupResult &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.das28FollowupResult
                      )
                    }
                    id={`das28FollowupResult2-${index}`}
                    name={`treatmentLines[${index}].das28FollowupResult`}
                    value={"ob"}
                    checked={
                      formik.values.treatmentLines[index]
                        .das28FollowupResult === "ob"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].das28FollowupResult`,
                        e.target.value
                      );
                    }}
                  />
                </RadioForm>
              </Grid>
              <Grid>
                <Input
                  isRequired={true}
                  label={"Wynik"}
                  isUpperCase={true}
                  name={`treatmentLines[${index}].followupResult`}
                  type={"number"}
                  maxWidth={"163px"}
                  colorLabel={theme.colors.grey1}
                  value={
                    formik.values.treatmentLines?.[index]?.followupResult || ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    formik.touched.treatmentLines?.[index]?.followupResult &&
                    Boolean(
                      (
                        formik.errors.treatmentLines?.[
                          index
                        ] as FormikErrors<ITreatmentLines>
                      )?.followupResult
                    )
                  }
                />
              </Grid>
              <Grid
                item
                justifyContent={"space-between"}
                pb={2}
                display={"flex"}
                flexDirection={"column"}
              >
                <Label
                  size={"small"}
                  isUpperCase={true}
                  color={theme.colors.grey1}
                  label={"Wynik skali"}
                />
                <Typography variant="caption" color={theme.colors.grey1}>
                  &lt;2,6 - remisja <br />
                  &le;3,2 – aktywność mała <br />
                  &gt;3,2 i &le;5,1 – aktywność umiarkowana <br />
                  &gt;5,1 – aktywność duża
                </Typography>
              </Grid>
            </Grid>

            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid item xs={2}>
                <SingleSelect
                  isRequired={true}
                  defaultValue={"Wybierz"}
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.followupVasResult &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                        ] as FormikErrors<ITreatmentLines>
                      )?.followupVasResult
                    )
                  }
                  label={"Wynik Vas"}
                  handleChange={(value) =>
                    setOption(value, "followupVasResult", index)
                  }
                  selectedValue={
                    formik.values.treatmentLines[index]?.followupVasResult ?? ""
                  }
                  elementName={`treatmentLines[${index}].followupVasResult`}
                  options={vasOptions}
                />
              </Grid>
              {formik.values.treatmentLines[index].das28FollowupResult ===
                "crp" && (
                <Grid item xs={2}>
                  <Input
                    isRequired={true}
                    label={"Wynik OB"}
                    isUpperCase={true}
                    name={`treatmentLines[${index}].followupObResult`}
                    type={"number"}
                    maxWidth={"163px"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.treatmentLines?.[index]?.followupObResult ||
                      ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.treatmentLines?.[index]
                        ?.followupObResult &&
                      Boolean(
                        (
                          formik.errors.treatmentLines?.[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.followupObResult
                      )
                    }
                  />
                </Grid>
              )}
              {formik.values.treatmentLines[index].das28FollowupResult ===
                "ob" && (
                <Grid>
                  <Input
                    isRequired={true}
                    label={"Wynik CRP"}
                    isUpperCase={true}
                    name={`treatmentLines[${index}].followupCrpResult`}
                    type={"number"}
                    maxWidth={"163px"}
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.treatmentLines?.[index]
                        ?.followupCrpResult || ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.treatmentLines?.[index]
                        ?.followupCrpResult &&
                      Boolean(
                        (
                          formik.errors.treatmentLines?.[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.followupCrpResult
                      )
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid>
                <Input
                  isRequired={true}
                  label={"Liczba bolesnych stawów"}
                  isUpperCase={true}
                  type={"number"}
                  name={`treatmentLines[${index}].followupTenderJointsCount`}
                  maxWidth={"72px"}
                  colorLabel={theme.colors.grey1}
                  value={
                    formik.values.treatmentLines?.[index]
                      ?.followupTenderJointsCount || ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    formik.touched.treatmentLines?.[index]
                      ?.followupTenderJointsCount &&
                    Boolean(
                      (
                        formik.errors.treatmentLines?.[
                          index
                        ] as FormikErrors<ITreatmentLines>
                      )?.followupTenderJointsCount
                    )
                  }
                />
              </Grid>
              <Grid>
                <Input
                  isRequired={true}
                  label={"Liczba obrzękniętych stawów"}
                  isUpperCase={true}
                  type={"number"}
                  name={`treatmentLines[${index}].followupSwollenJointsCount`}
                  maxWidth={"72px"}
                  colorLabel={theme.colors.grey1}
                  value={
                    formik.values.treatmentLines?.[index]
                      ?.followupSwollenJointsCount || ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    formik.touched.treatmentLines?.[index]
                      ?.followupSwollenJointsCount &&
                    Boolean(
                      (
                        formik.errors.treatmentLines?.[
                          index
                        ] as FormikErrors<ITreatmentLines>
                      )?.followupSwollenJointsCount
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                <Label
                  size={"small"}
                  isUpperCase={true}
                  color={theme.colors.grey1}
                  label={"Czy były objawy nietolerancji wybranego leku?"}
                  isRequired={true}
                />
                <RadioForm>
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.symptomsOfIntolerance &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.symptomsOfIntolerance
                      )
                    }
                    id={`symptomsOfIntolerance1-${index}`}
                    name={`treatmentLines[${index}].symptomsOfIntolerance`}
                    value={"tak"}
                    checked={
                      formik.values.treatmentLines[index]
                        .symptomsOfIntolerance === "tak"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].symptomsOfIntolerance`,
                        e.target.value
                      );
                    }}
                  />
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.symptomsOfIntolerance &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                          ] as FormikErrors<ITreatmentLines>
                        )?.symptomsOfIntolerance
                      )
                    }
                    id={`symptomsOfIntolerance2-${index}`}
                    name={`treatmentLines[${index}].symptomsOfIntolerance`}
                    value={"nie"}
                    checked={
                      formik.values.treatmentLines[index]
                        .symptomsOfIntolerance === "nie"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].symptomsOfIntolerance`,
                        e.target.value
                      );
                    }}
                  />
                </RadioForm>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid mt={1} display={"flex"} item xs={12} justifyContent={"center"}>
        <ButtonAddDrug onClick={addLine}>
          Dodaj linię leczenia
          <Plus />
        </ButtonAddDrug>
      </Grid>
    </Grid>
  );
});
