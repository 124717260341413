import {Grid} from "@mui/material";
import {observer} from "mobx-react-lite";

// hooks
import {useRzsStore} from "../../../../../../../../store/hooks";

// components
import {GroupForm, Label, NormalText, NormalTextDiv,} from "../../../virusology/hiv/HivViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hiv/HivViewCase.helper";

export const PatientSection = observer(() => {
  const caseStore = useRzsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5}>
      <Grid item xs={12} mb={"36px"}>
        <GroupForm>Pacjent: {caseElement.initials}</GroupForm>
      </Grid>

      <Grid item xs={12}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={2}>
            <Label>WIEK</Label>
            <NormalText>{checkValueIsEmpty(caseElement.age)}</NormalText>
          </Grid>
          <Grid item xs={2}>
            <Label>PŁEĆ</Label>
            <NormalText>
              {(checkValueIsEmpty(caseElement.gender))}
            </NormalText>
          </Grid>
          <Grid item xs={2}>
            <Label>Masa ciała w kg</Label>
            <NormalText>{caseElement.weightKg}</NormalText>
          </Grid>
          <Grid item xs={2}>
            <Label>Wzrost w cm</Label>
            <NormalText>{caseElement.heightCm}</NormalText>
          </Grid>
          <Grid item xs={2}>
            <Label>BMI</Label>
            <NormalText>{caseElement.bmi}</NormalText>
          </Grid>
          {caseElement.durationRzs && <Grid item xs={6} mt={3}>
            <Label>Czas trwania reumatoidalnego zapalenia stawów (rzs)</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.durationRzs)}
            </NormalText>
          </Grid>}
          <Grid item xs={12} mt={3}>
            <Grid container borderRadius={1} bgcolor={"white"}>
              <Grid item xs={12}>
                <Label>
                  Choroby towarzyszące
                </Label>
                <NormalTextDiv>
                  {caseElement.comorbidities.length > 0 && (
                    <ul>
                      <>
                        {caseElement.comorbidities.map((element) => (
                          <li key={element}>{element}</li>
                        ))}
                      </>
                    </ul>
                  )}
                </NormalTextDiv>
              </Grid>
            </Grid>
          </Grid>
          {caseElement.comorbiditiesOther && <Grid item xs={6} mt={3}>
            <Label>Inne</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.comorbiditiesOther)}
            </NormalText>
          </Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={5}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={3}>
            <Label $size={"large"} $mb={0}>
              WYWIAD
            </Label>
          </Grid>
          <Grid container ml={2}>
            <Grid item xs={6} mb={2}>
              <Label>HORMONALNA TERAPIA ZASTĘPCZA (HTZ)</Label>
              <NormalText>{checkValueIsEmpty(caseElement.htz)}</NormalText>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Label>HORMONALNA TERAPIA ZASTĘPCZA (HTZ)</Label>
              <NormalText>{checkValueIsEmpty(caseElement.htz)}</NormalText>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Label>PALENIE TYTONIU</Label>
              <NormalText>{checkValueIsEmpty(caseElement.smoking)}</NormalText>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Label>LICZBA PACZKOLAT</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.packYears)}
              </NormalText>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Label>ŁUSZCZYCA</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.psoriasis)}
              </NormalText>
            </Grid>
            <Grid item xs={6} mb={2}>
              <Label>CZYNNIK REUMATOIDALNY W SUROWICY LUB ACCP</Label>
              <NormalText>{checkValueIsEmpty(caseElement.accp)}</NormalText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
