import { useEffect } from 'react';
import { useParams } from 'react-router';


// types 
import { EnAreaNames, EnFormNames } from '../../../../../store/caseSettingsStore';

// hooks
import { useCaseApi } from '../../../../../apiHooks';
import { HivStore } from '../../../../../store/forms/hivStore';
import { RzsStore } from '../../../../../store/forms/rzsStore';

export const useViewCase = (area: EnAreaNames, form: EnFormNames, store: HivStore | RzsStore) => {
    const { getCase } = useCaseApi();
    const { caseId} = useParams();
  
    useEffect(() => {
      if (caseId ) {
         void getCase({area, form, caseId});
      }
    }, [getCase, caseId, area, form]);
  
    useEffect(() => {
      return () => {
        store.resetCase();
      };
    }, [store]);
  
  
    return {
        isCase: Boolean(store.case)
    }
}