import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// hooks
import {
  useCaseSettingsStore,
  useHivStore,
} from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";

// types
import { THivStage04 } from "./type";

// lib
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";

export const validationSchema = yup.object().shape({
  infoAboutTherapy: yup.string().required(),
  conclusion: yup.string().required(),
  conclusionCheckbox: yup.boolean(),
});

const initialValues: THivStage04 = {
  infoAboutTherapy: "",
  conclusion: "",
  conclusionCheckbox: false,
};

export const useFourthStageVm = () => {
  // params and location
  const params = useParams();
  const { area, form, caseId } = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {
    isNextButtonClicked,
    isPrevButtonClicked,
    currentStage,
    statusDraftButtonClicked,
  } = caseSettingsStore;

  // define API calls
  const {
    deleteConclusionFileFourthStage,
    postConclusionFileFourthStage,
    updateCase,
  } = useCaseApi();

  // rzs store
  const hivStore = useHivStore();
  const { fourthStage, caseStage } = hivStore;

  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<THivStage04>({
    enableReinitialize: true,
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );

      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false
  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await formik.submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  useEffect(() => {
    if (statusDraftButtonClicked === "click" && hivStore.case !== null) {
      hivStore.setCase({
        ...hivStore.case,
        ...formik.values,
      });
      caseSettingsStore.saveStageDraftButton();
    }
  }, [statusDraftButtonClicked, formik.values, hivStore, caseSettingsStore]);

  // handlers conclusionFile
  const addConclusionFile = async (file: File | null) => {
    if (file && caseId) {
      await postConclusionFileFourthStage(+caseId, file);
    }
  };

  const removeConclusionFile = async (idFile: number | string) => {
    if (idFile && caseId) {
      await deleteConclusionFileFourthStage(+caseId, idFile);
    }
  };

  return {
    formik,
    conclusionFiles:
      fourthStage && fourthStage.conclusionFiles
        ? fourthStage.conclusionFiles
        : [],
    removeConclusionFile,
    addConclusionFile,
  };
};
