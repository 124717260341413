import * as yup from "yup";

// hooks
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  useCaseSettingsStore,
  useHivStore,
} from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";

// types
import { THivStage01 } from "./type";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";

// lib
import { RegexRules } from "../../../../../../../../../utils";

export const validationSchema = yup.object().shape({
  title: yup.string().required(),
  age: yup.number().required(),
  gender: yup.string().required(),
  initials: yup.string().max(3).matches(RegexRules.INITIALS).required(),
  coInfection: yup.number().min(0).required(),
  firstTreatment: yup.boolean(),
});

const initialValues: THivStage01 = {
  title: "",
  age: null,
  gender: null,
  initials: "",
  coInfection: null,
  firstTreatment: null,
};

export const useStage01ViewModel = () => {
  // params and location
  const params = useParams();
  const location = useLocation();
  const { area, form, caseId } = params;
  const navigate = useNavigate();

  // define is create or edit case page
  const isCreateCasePage = !caseId && location.pathname.includes("add");

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const { isNextButtonClicked, isPrevButtonClicked, currentStage } =
    caseSettingsStore;

  // define API calls
  const caseApi = useCaseApi();
  const { firstStage, caseStage } = useHivStore();

  const valuesObj =
    caseStage && caseStage > currentStage!
      ? JSON.parse(JSON.stringify(firstStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<THivStage01>({
    enableReinitialize: true,
    initialValues: {
      ...valuesObj,
    },
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );

      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (isCreateCasePage) {
        const createNewCaseResponse = await caseApi.createNewCase(
          values,
          area as EnAreaNames,
          form as EnFormNames
        );

        if (createNewCaseResponse) {
          caseSettingsStore.increaseCurrentStage();
          navigate(`/case/edit/${area}/${form}/${createNewCaseResponse}`);
        } else {
          throw new Error(
            "Formik submit first stage error, some rules are broken"
          );
        }
      } else {
        if (!caseId) throw new Error("Case ID is missing");
        if (!currentStage) throw new Error("Current stage is missing");

        await caseApi.updateCase({
          area: area as EnAreaNames,
          form: form as EnFormNames,
          caseId,
          value: values,
          stage: currentStage,
        });
      }
    },
  });

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false
  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await formik.submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  return {
    formik,
  };
};
