import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";

// types
import { CaseStatus } from "../../../../../../interfaces/user/draftCase/draft";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../store/caseSettingsStore";

// hooks
import { useCaseApi } from "../../../../../../apiHooks";
import { useAuthStore } from "../../../../../../store/hooks";

// components
import { Grid } from "@mui/material";
import { Button } from "../../../../../shared/button/Button";
import { WritingComment } from "./writingComment/WritingComment";
import { Comments } from "./comments/Comments";
import {
  statusColors,
  statusIcons,
  statusName,
} from "../../../../../shared/user/cards/CaseCard";
import { TinyText } from "../virusology/hiv/HivViewCase.styled";
import { Icon } from "../../../../../shared/user/cards/CaseCard.styled";
import { AdminTitle } from "./TopViewCase.styled";

// lib
import { UserLink, generateLink } from "../../../../../../utils";
import { checkPermission } from "../../../../../../utils/checkPermission";
import { RoleFunction } from "../../../../../../constants/projectUserRoles";
import { HivStore } from "../../../../../../store/forms/hivStore";
import { RzsStore } from "../../../../../../store/forms/rzsStore";
import theme from "../../../../../../theme/theme";

interface ITopViewCaseProps {
  caseStore: HivStore | RzsStore;
}

export const TopViewCase: FC<ITopViewCaseProps> = observer(({ caseStore }) => {
  // params and navigate
  const { caseId, area, form } = useParams();
  const navigate = useNavigate();

  // define API calls
  const { getCasePDF } = useCaseApi();

  // store logic
  const authStore = useAuthStore();
  const { roleFunction } = authStore;
  const { case: caseElement } = caseStore;

  const [writingCommentVisible, setWritingCommentVisible] = useState(false);

  const casePdfHandler = async () => {
    if (caseId) {
      const res = await getCasePDF(
        +caseId,
        area as EnAreaNames,
        form as EnFormNames
      );
      const blob = new Blob([res], { type: "application/zip" });
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const caseEditHandler = () => {
    navigate(
      generateLink(UserLink.EDIT_CASE, [
        [":caseId", String(caseId)],
        [":area", String(area)],
        [":form", String(form)],
      ])
    );
  };

  if (!caseElement) {
    return <></>;
  }

  const statusIcon = statusIcons[caseElement.status];
  const statusColor = statusColors[caseElement.status];
  const statusNameTitle = statusName[caseElement.status].toUpperCase();

  return (
    <Grid container>
      <Grid item xs={3} display={"flex"} flexDirection={"column"}>
        <TinyText>
          <strong>FORMULARZ:</strong>
          {String(form).toUpperCase()}
        </TinyText>
        <TinyText>
          <strong>ID OPISU PRZYPADKU:</strong>
          {caseElement.customId}
        </TinyText>
        <TinyText>
          <strong>AUTOR:</strong>
          {caseElement.createdBy}
        </TinyText>
        <TinyText>
          <strong>ID PACJENTA:</strong>
          {caseElement.initials.toUpperCase()}
        </TinyText>
      </Grid>
      <Grid item xs={2}>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          flexWrap={"nowrap"}
          justifyContent={"center"}
        >
          <Icon $position={"static"} color={statusColor}>
            <img src={statusIcon} alt={"status"} />
          </Icon>
          <div>
            <TinyText>
              <strong>STATUS:</strong>
            </TinyText>
            <TinyText>{statusNameTitle}</TinyText>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={7}
        display={"flex"}
        justifyContent={"flex-end"}
        gap={"20px"}
      >
        {checkPermission(roleFunction, [RoleFunction.ROLE_PARTICIPANT]) && (
          <Button
            size={"tiny"}
            text={"Zapytaj"}
            clickHandler={() =>
              setWritingCommentVisible((prevState) => !prevState)
            }
            width={"168px"}
            backgroundColor={theme.colors.blue2}
            color={"white"}
          />
        )}
        {checkPermission(roleFunction, [RoleFunction.ROLE_PUBLISHING]) &&
          caseElement.status === CaseStatus.REJECT && (
            <Button
              clickHandler={caseEditHandler}
              size={"tiny"}
              text={"Edytuj"}
              width={"168px"}
              backgroundColor={theme.colors.orange}
              color={"white"}
            />
          )}
        <Button
          clickHandler={casePdfHandler}
          size={"tiny"}
          text={"Pobierz pdf"}
          width={"168px"}
          backgroundColor={theme.colors.blue2}
          color={"white"}
        />
      </Grid>
      {caseElement.comments && caseElement.comments.length > 0 && (
        <Grid xs={12} mt={"40px"}>
          <Comments comments={caseElement.comments} />
        </Grid>
      )}
      <Grid item xs={12} mt={"40px"}>
        <AdminTitle>{caseElement.title}</AdminTitle>
      </Grid>
      {writingCommentVisible && (
        <WritingComment
          handleModalClose={() => setWritingCommentVisible(false)}
        />
      )}
    </Grid>
  );
});
