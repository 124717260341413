import {observer} from "mobx-react";

// hooks
import {useRzsStore} from "../../../../../../../../store/hooks";

// components
import {Grid} from "@mui/material";
import {Label, NormalText} from "../../../virusology/hiv/HivViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hiv/HivViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import React from "react";

export const TreatmentProgramSection = observer(() => {
  const caseStore = useRzsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5} bgcolor={"white"} p={2}>
      <Grid item xs={12} mb={3}>
        <Label $size={"large"} $mb={0}>
          PROGRAM LEKOWY
        </Label>
      </Grid>
      <Grid container ml={2}>
        <Grid item xs={4}>
          <Label>DATA WEJŚCIA DO PROGRAMU LEKOWEGO</Label>
          <NormalText>
            {checkValueIsEmpty(dateStringify(caseElement.programEntryDate))}
          </NormalText>
        </Grid>
        <Grid item xs={4}>
          <Label>WYNIK DAS 28</Label>
          <NormalText>
            {checkValueIsEmpty(caseElement.das28ResultMoi)}
          </NormalText>
        </Grid>
        <Grid item xs={4} mt={2}>
          <Label>WYNIK*</Label>
          <NormalText>{checkValueIsEmpty(caseElement.resultMoi)}</NormalText>
        </Grid>
        <Grid item xs={4} mt={2}>
          <Label>WYNIK VAS</Label>
          <NormalText>{checkValueIsEmpty(caseElement.vasResultMoi)}</NormalText>
        </Grid>
        <Grid item xs={4} mt={2}>
          <Label>
            WYNIK {caseElement.das28ResultMoi === "crp" ? "CRP" : "OB"}*
          </Label>
          <NormalText>
            {checkValueIsEmpty(
              caseElement.das28ResultMoi === "crp"
                ? caseElement.crpResultMoi
                : caseElement.obResultMoi
            )}
          </NormalText>
        </Grid>
        <Grid item xs={4} mt={2}></Grid>
        <Grid item xs={4} mt={2}>
          <Label>LICZBA BOLESNYCH STAWÓW</Label>
          <NormalText>
            {checkValueIsEmpty(caseElement.tenderJointsCountMoi)}
          </NormalText>
        </Grid>
        <Grid item xs={4} mt={2}>
          <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
          <NormalText>
            {checkValueIsEmpty(caseElement.swollenJointsCountMoi)}
          </NormalText>
        </Grid>
      </Grid>


      <Grid container mt={5} bgcolor={"white"} p={2}>
        {caseElement.treatmentLines.map((drug, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} mb={3}>
              <Label $size={"large"} $mb={0}>
                {`Linia leczenia ${index + 1}`}
              </Label>
            </Grid>
            <Grid container ml={2} mb={2}>
              <Grid item xs={12}>
                <Label>WARIANT</Label>
                <NormalText>{checkValueIsEmpty(drug.variants)}</NormalText>
              </Grid>
              {drug.otherVariantType && <Grid item xs={12} mt={2}>
                <Label>Inne Rodzaj</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.otherVariantType)}
                </NormalText>
              </Grid>}
              {drug.dose && <Grid item xs={12} mt={2}>
                <Label>Inne dawka</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.dose)}
                </NormalText>
              </Grid>}
              {drug.tnfInhibitorType && <Grid item xs={12} mt={2}>
                <Label>Rodzaj inhibitora TNF</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.tnfInhibitorType)}
                </NormalText>
              </Grid>}
              {drug.adalimumabOtherVariant && <Grid item xs={12} mt={2}>
                <Label>adalimumab inne rodzaj</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.adalimumabOtherVariant)}
                </NormalText>
              </Grid>}
              {drug.adalimumabOtherDose && <Grid item xs={12} mt={2}>
                <Label>adalimumab inne dawka</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.adalimumabOtherDose)}
                </NormalText>
              </Grid>}
              {drug.il6Dose && <Grid item xs={12} mt={2}>
                <Label>Rodzaj inhibitora IL-6:</Label>
                <Label>Dawka</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.il6Dose)}
                </NormalText>
              </Grid>}

              {drug.jakInhibitorType && <Grid item xs={12} mt={2}>
                <Label>Rodzaj inhibitora JAK</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.jakInhibitorType)}
                </NormalText>
              </Grid>}
              <Grid item xs={12}>
                <Label>Monoterapia czy kombinacja z innym lekiem?*</Label>
                <NormalText>{checkValueIsEmpty(drug.monotherapy)}</NormalText>
              </Grid>
              <Grid item xs={12}>KOMBINACJA 1,2,3,4,5....</Grid>

              <Grid item xs={4}>
                <Label>WYNIK DAS 28</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.das28FollowupResult)}
                </NormalText>
              </Grid>
              <Grid item xs={4} mt={2}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(drug.followupResult)}</NormalText>
              </Grid>
              <Grid item xs={4} mt={2}>
                <Label>WYNIK VAS</Label>
                <NormalText>{checkValueIsEmpty(drug.followupVasResult)}</NormalText>
              </Grid>
              <Grid item xs={4} mt={2}>
                <Label>
                  WYNIK {drug.das28FollowupResult === "crp" ? "CRP" : "OB"}*
                </Label>
                <NormalText>
                  {checkValueIsEmpty(
                    drug.das28FollowupResult === "crp"
                      ? drug.followupCrpResult
                      : drug.followupObResult
                  )}
                </NormalText>
              </Grid>
              <Grid item xs={4} mt={2}></Grid>
              <Grid item xs={4} mt={2}>
                <Label>LICZBA BOLESNYCH STAWÓW</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.followupTenderJointsCount)}
                </NormalText>
              </Grid>
              <Grid item xs={4} mt={2}>
                <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.followupSwollenJointsCount)}
                </NormalText>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Label>Czy były objawy nietolerancji wybranego leku?</Label>
                <NormalText>{checkValueIsEmpty(drug.symptomsOfIntolerance)}</NormalText>
              </Grid>

            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
});
