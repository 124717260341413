import { makeAutoObservable } from "mobx";
import { THiv } from "../../components/pages/user/draftCase/forms/virusology/hiv/type";
import { THivStage01 } from "../../components/pages/user/draftCase/forms/virusology/hiv/stages/stage01/type";
import { THivStage02 } from "../../components/pages/user/draftCase/forms/virusology/hiv/stages/stage02/type";
import { THivStage03 } from "../../components/pages/user/draftCase/forms/virusology/hiv/stages/stage03/type";
import { THivStage04 } from "../../components/pages/user/draftCase/forms/virusology/hiv/stages/stage04/type";

export class HivStore {
  case: THiv | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: THiv) {
    this.case = value;
  }

  get caseStage() {
    return this.case?.step;
  }

  get firstStage() {
    return this.case as THivStage01;
  }

  get secondStage() {
    return this.case as THivStage02;
  }

  get thirdStage() {
    return this.case as THivStage03;
  }

  get fourthStage() {
    return this.case as THivStage04;
  }

  resetCase() {
    this.case = null;
  }
}
