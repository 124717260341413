import { useEffect } from "react";

// hooks
import { useFormik } from "formik";
import {
  useCaseSettingsStore,
  useRzsStore,
} from "../../../../../../../../../store/hooks";

// types
import { TRzsStage05 } from "./type";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";
import { IFile } from "../../../../../../../../../interfaces/user/draftCase/file";

const initialValues: TRzsStage05 = {
  conclusions: "",
  statement: false,
  rzsFiles: [],
};

export const useStage05ViewModel = () => {
  // params and location
  const params = useParams();
  const { area, form, caseId } = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {
    isNextButtonClicked,
    isPrevButtonClicked,
    currentStage,
    statusDraftButtonClicked,
  } = caseSettingsStore;

  // rzs store
  const rzsStore = useRzsStore();
  const { fifthStage, caseStage } = rzsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fifthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TRzsStage05>({
    initialValues: {
      ...valuesObj,
    },
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false
  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await formik.submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  const callback = (res: IFile[]) => {
    formik.setFieldValue("rzsFiles", [...res]);
  };
  useEffect(() => {
    if (statusDraftButtonClicked === "click" && rzsStore.case !== null) {
      rzsStore.setCase({
        ...rzsStore.case,
        ...formik.values,
      });
      caseSettingsStore.saveStageDraftButton();
    }
  }, [statusDraftButtonClicked, formik.values, rzsStore, caseSettingsStore]);

  const addPicturesFile = async (file: File | null) => {
    if (file && caseId) {
      await caseApi.postFileFifthStage(+caseId, file, callback);
    }
  };

  const removePicturesFile = async (idFile: number | string) => {
    if (idFile && caseId) {
      await caseApi.deleteFileFifthStage(+caseId, idFile, callback);
    }
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    await formik.submitForm();
  };

  return {
    formik,
    removePicturesFile,
    addPicturesFile,
    onSubmitHandler
  };
};
