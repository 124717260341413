import { observer } from 'mobx-react'
import React from "react";

import { AdminLayout } from '../../../shared/admin/layout/AdminLayout'
import {useForms} from "./useForms";
import {FormsContainer, Title, TopContentContainer} from "./Forms.styled";
import {CustomTable} from "../../../shared/customTables/customTable/CustomTable";
import {Button} from "../../../shared/button/Button";
import theme from "../../../../theme/theme";
import {Modal} from "../../../shared/modal/Modal";
import {ModalType} from "../../../../constants/modalType";
import {DeleteModal} from "../../../shared/modal/sharedModals/deleteModal/DeleteModal";
import {ConfirmationModal} from "../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal";

export const Forms = observer(() => {
    const {
        isFormsLoaded,
        generateActions,
        headCells,
        cellsSwitcher,
        newHandler,
        tableDataRows,
        isModalOpened,
        closeModal,
        modalType,
        deleteHandlerYes
    } = useForms()

    return (
        <AdminLayout>
            {isFormsLoaded &&
              <FormsContainer>
                <CustomTable
                  cellsSwitcher={cellsSwitcher}
                  searchBarAddedContent={
                      <>
                          <TopContentContainer>
                              <Button clickHandler={newHandler} text={'Dodaj formularz +'} size={'tiny'}
                                      width={'270px'}
                                      backgroundColor={theme.colors.white}
                                      hoverColor={theme.colors.white}
                                      fontWeight={700} color={theme.colors.abbvie}/>
                          </TopContentContainer>
                          <Title>Formularze</Title>
                      </>
                  }
                  isSearchBar={false} tableDataRows={tableDataRows} isDataDownloaded={true} headCells={headCells}
                  actions={generateActions()}/>
              </FormsContainer>}
                {isModalOpened &&
                  <Modal clickHandler={() => closeModal()}>
                      {modalType === ModalType.DELETE_MODAL &&
                        <DeleteModal handleNo={() => closeModal()} handleYes={deleteHandlerYes}
                                     textAdded={'ten formularz'}/>
                      }
                      {modalType === ModalType.DELETE_CONFIRMATION_MODAL &&
                        <ConfirmationModal text={<p>Użytkownik został <b>usunięty poprawnie</b>.</p>}/>
                      }
                  </Modal>
                }
        </AdminLayout>
    )
})