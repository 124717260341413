import theme from "../../../../theme/theme";
import  { FC } from "react";
import { observer } from "mobx-react-lite";
import { Grid } from '@mui/material';

import { Input } from '../../ui/input/Input';
import { ErrorMessage } from '../../ui/errorMessage/ErrorMesage';
import { FormButtons } from '../../ui/formButton/FormButtons';
import { MultipleSelect } from '../../ui/multipleSelect/MultipleSelect';
import { useFormTypeForm } from './useFormTypeForm';
import { Container, Label } from "./FormTypeForm.styled";
import {IFormTypeForm} from "../../../../interfaces/admin/form/formElementForm";

interface IProps {
  isEdit?: boolean;
  link: string;
  formTypeForm?: IFormTypeForm | null;
  actionHandler: (formTypeForm: IFormTypeForm) => Promise<string | null>;
  handleNo?: () => void;
}

export const FormTypeForm: FC<IProps> = observer(({isEdit, link, formTypeForm, actionHandler, handleNo}) => {
  const {
    formik,
    isButtonActive,
    confirmForm,
    message, 
    handleChange,
    selectFormsOptions,
    selectedForms,
  } = useFormTypeForm(actionHandler, formTypeForm)

  return (
    <Container>
        <Input color={theme.colors.grey1}
               colorLabel={theme.colors.grey1}
               borderColor={theme.colors.grey5}
               placeholderColor={theme.colors.grey4}
               background={'white'}
               isRequired={true}
               label={'Nazwa formularza'}
               name={'title'}
               isUpperCase={true}
               type={'text'}
               value={formik.values.title}
               onChange={formik.handleChange}
               placeholder={'Wpisz'}
               isError={formik.touched.title && Boolean(formik.errors.title)}/>
         <Grid>
          <Label>
            Obszary terapeutyczne
          </Label>
          <MultipleSelect
            selectedValues={selectedForms}
            elementName={'areas'}
            options={selectFormsOptions}
            handleChange={handleChange}
            subtitle={'Wybierz'}
          />
        </Grid>             
      {formik.submitCount > 0 &&
        <ErrorMessage errors={formik.errors} errorServer={false} message={message}/>
      }
      <FormButtons isButtonActive={isButtonActive} confirmForm={confirmForm}
                   link={link} onClickCancel={handleNo}
                   buttonText={isEdit ? 'zapisz' : 'zapisz i dodaj +'}/>
    </Container>
  )
});
