import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import * as yup from "yup";
import { useFormik } from "formik";
import { SelectChangeEvent } from '@mui/material';

import { ValidationMessages } from '../../../../constants/validationMessages';
import { useModal } from "../../modal/useModal.vm";
import { IOption } from '../../../../interfaces/common/form/option';
import {useAreasStore} from '../../../../store/hooks';
import {IFormTypeForm} from "../../../../interfaces/admin/form/formElementForm";
import {useAreasApi} from "../../../../apiHooks";
export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required()
});

export const useFormTypeForm = (actionHandler: (formTypeForm: IFormTypeForm) => Promise<string | null>, formTypeForm?: IFormTypeForm | null) => {
  const {getAreas} = useAreasApi()
  const formsStore = useAreasStore();
  const {areas, isAreasLoaded} = formsStore;
  const {handleModal, isModalOpened} = useModal()
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [message, setMessage] = useState('')
  const [selectedForms, setSelectedForms] = useState<number[]>([])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: formTypeForm ? formTypeForm.title : '',
      areas: formTypeForm ? formTypeForm.areas : [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {

      const formTypeFormNew: IFormTypeForm = {
        title: values.title,
        areas: [...selectedForms],
      }
      const message = await actionHandler(formTypeFormNew);

      if (message === null && !formTypeForm) {
        resetForm()
      } else if(message !== null) {
        setMessage(message);
      }

    }
  })

  const createErrorMessage = useCallback(() => {
    if (Object.keys(formik.errors).length > 0) {
      return ValidationMessages.INVALID_OR_EMPTY_FORM + ' ' + ValidationMessages.INVALID_FORM;
    }

    return ""
  }, [formik.errors])

  useEffect(() => {
    void getAreas()
  }, [getAreas])

  useEffect(() => {
    if (formik.submitCount !== 0) {
      setMessage(createErrorMessage());
      const isValid = Object.keys(formik.errors).length === 0;
      setIsButtonActive(isValid);
    } else {
      const isValid = !!formik.values.title;
      setIsButtonActive(isValid);
    }
  }, [createErrorMessage, formik.errors, formik.submitCount, formik.values.title])

  useEffect(() => {
    setSelectedForms(formTypeForm ? formTypeForm.areas : [])
  }, [formTypeForm, setSelectedForms])

  const handleChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedForms.includes(0)) {
        setSelectedForms(selectedValues.filter(value => value !== 0));
      } else {
        if (selectFormsOptions) {
          setSelectedForms([...selectFormsOptions.map((option: IOption) => option.id), 0]);
        }
      }
    } else {
      if (selectedForms.includes(0)) {
        setSelectedForms([]);
      } else {
        setSelectedForms(selectedValues);
      }
    }
  };

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  const selectFormsOptions: IOption[] = areas ? [...areas.map(area => ({title: area.title, id: area.id}))] : [];


  return {
    formik,
    isButtonActive,
    confirmForm,
    message,
    handleModal,
    isModalOpened,
    handleChange,
    selectFormsOptions,
    selectedForms,
    areas,
    isAreasLoaded
  }
}