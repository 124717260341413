// enum
import { EnFormNames } from "../../../../../../store/caseSettingsStore";
// settings
import { rzsStages } from "../../forms/rheumatology/rzs/rzsStages";
import { hivStages } from "../../forms/virusology/hiv/hivStages";

export const stageSwitcher = (currentForm: EnFormNames) => {
  switch (currentForm) {
    case EnFormNames.hiv:
      return hivStages;
    case EnFormNames.rzs:
      return rzsStages;
    default:
      return [];
  }
};
