import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { createTableRows } from "../../../shared/customTables/utils/createTableRows";
import { useModal } from "../../../shared/modal/useModal.vm";
import { TableCellData } from "../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled";
import { CellTime } from "../../../shared/customTables/cells/cellTime/CellTime";
import { ActionButton } from "../../../shared/customTables/button/ActionButton";
import { IAction, IHeadCell } from "../../../../interfaces/common/table";
import { useAuthStore, useCasesStore } from "../../../../store/hooks";
import { useCasesApi } from "../../../../apiHooks";
import { ModalType } from "../../../../constants/modalType";
import { CaseStatus } from "../../../../interfaces/user/draftCase/draft";
import {
  AdminLink,
  generateLink,
  SplitData,
  splitData,
  UserLink,
} from "../../../../utils";
import { StatusText } from "../../../shared/admin/status/Status.styled";
import { IconContainer } from "./Cases.styled";
import theme from "../../../../theme/theme";
import { ReactComponent as PublicIcon } from "../../../../assets/icon/public.svg";
import { ReactComponent as UnpublicIcon } from "../../../../assets/icon/unpublic.svg";
import { ReactComponent as HistoryIcon } from "../../../../assets/icon/history.svg";
import { ReactComponent as ViewLensIcon } from "../../../../assets/icon/viewLens.svg";
import { ReactComponent as CheckboxCheckIcon } from "../../../../assets/icon/checkboxCheck.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icon/close.svg";
import { RoleFunction } from "../../../../constants/projectUserRoles";
import { SelectString } from "../../../shared/ui/selectString/SelectString";
import { Grid } from "@mui/material";
import { IOptionString } from "../../../../interfaces/common/form/option";
import { filterCases } from "./useCases.helper";
import { IForm } from "../../../../interfaces/admin/cases/cases";

type SelectedStatus =
  | "Do rozpatrzenia"
  | "Zaakceptowany"
  | "Do edycji"
  | "Status";

export const useCases = () => {
  const [actionId, setActionId] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<CaseStatus | "">("");
  const [selectedForm, setSelectedForm] = useState<number | "">("");
  const navigate = useNavigate();
  const casesStore = useCasesStore();
  const { cases, forms, isCasesLoaded } = casesStore;
  const [isPublic, setIsPublic] = useState<boolean | null>(null);
  const { getCases, acceptCase, editStatusCase, rejectCase } = useCasesApi();
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();
  const authStore = useAuthStore();
  const { roleFunction } = authStore;

  useEffect(() => {
    if (!isCasesLoaded) {
      if (cases === null) {
        void getCases();
      } else {
        void getCases(false);
      }
    }
  }, [getCases, cases, isCasesLoaded]);

  useEffect(() => {
    return () => {
      casesStore.resetCases();
    };
  }, [casesStore]);

  const changeStatusHandler = (id: number) => {
    setActionId(+id);
    if (cases) {
      const element = cases.find((element) => element.id === id);
      if (element) {
        setIsPublic(element.isPublic);
      }
    }

    handleModal(ModalType.EDIT_STATUS_CASE_MODAL);
  };

  const generatePublicationStatusIcon = (id: number) => {
    if (cases) {
      const element = cases.find((element) => element.id === id);
      const isAccept = element?.status === CaseStatus.ACCEPT;
      if (element && element.status !== CaseStatus.SENT) {
        return (
          <IconContainer
            $isAction={isAccept}
            onClick={() => isAccept && changeStatusHandler(id)}
          >
            {element.isPublic ? <PublicIcon /> : <UnpublicIcon />}{" "}
          </IconContainer>
        );
      }
    }
    return null;
  };

  const generateHistoryBackground = (id: number) => {
    if (cases) {
      const element = cases.find((element) => element.id === id);
      if (element) {
        return element.countComments > 0 ? "white" : "transparent";
      }
    }
    return "transparent";
  };

  const generateHistoryIcon = (id: number) => {
    if (cases) {
      const element = cases.find((element) => element.id === id);
      if (element) {
        return element.countComments > 0 ? <HistoryIcon /> : null;
      }
    }
    return null;
  };

  const generateHistoryDisabled = (id: number) => {
    if (cases) {
      const element = cases.find((element) => element.id === id);
      if (element) {
        return !(element.countComments > 0);
      }
    }
    return false;
  };

  const navigateHistoryHandler = (id: number | string) => {
    if (!generateHistoryDisabled(+id)) {
      navigate(generateLink(AdminLink.HISTORY, id));
    }
  };

  const viewHandler = (id: number | string) => {
    const findCase = cases && cases.find((c) => c.id === id);
    if (findCase) {
      navigate(
        generateLink(UserLink.VIEW_CASE, [
          [":caseId", String(id)],
          [":area", String(findCase.area.name)],
          [":form", String(findCase.form.name)],
        ])
      );
    }
  };

  const acceptCaseModalHandler = async (id: number | string) => {
    setActionId(+id);
    setIsPublic(null);
    handleModal(ModalType.ACCEPT_CASE_MODAL);
  };

  const acceptCaseModalAcceptingStart = async () => {
    if (actionId) {
      const res = await acceptCase(+actionId, isPublic ?? true);
      if (res) {
        handleModal(ModalType.ACCEPT_CASE_MODAL);
      }
      await getCases();
    }
  };

  const acceptCaseByAccepting = (id: number | string) => {
    setActionId(+id);
    setIsPublic(null);
    handleModal(ModalType.ACCEPT_CASE_MODAL_START);
  };

  const acceptCaseHandler = async (isPublic: boolean) => {
    setIsPublic(isPublic);
    if (actionId !== null) {
      const res = await acceptCase(actionId, isPublic);
      if (res) {
        await getCases();
        setActionId(null);
        setIsPublic(null);
        handleModal(ModalType.ACCEPT_CASE_CONFIRMATION_MODAL);
      }
    }
  };

  const editStatusCaseHandler = async (isPublic: boolean) => {
    setIsPublic(isPublic);
    if (actionId !== null) {
      const res = await editStatusCase(actionId, isPublic);
      if (res) {
        await getCases();
        setActionId(null);
        setIsPublic(null);
        closeModal();
      }
    }
  };

  const rejectCaseModalHandler = (id: number | string) => {
    setActionId(+id);
    handleModal(ModalType.REJECT_CASE_MODAL);
  };

  const rejectCaseHandler = async (comment: string) => {
    if (actionId !== null) {
      const res = await rejectCase(actionId, comment);
      if (res) {
        await getCases();
        setActionId(null);
        handleModal(ModalType.REJECT_CASE_CONFIRMATION_MODAL);
      }
    }
  };

  const generateStatusActions = (id: number | string) => {
    if (cases) {
      const element = cases.find((element) => element.id === id);
      if (element) {
        switch (element.status) {
          case CaseStatus.REJECT:
            return (
              <StatusText color={theme.colors.red}>
                <span>do edycji</span>
              </StatusText>
            );
          case CaseStatus.ACCEPT:
            return (
              <StatusText color={theme.colors.green}>
                <span>zaakceptowany</span>
              </StatusText>
            );
          case CaseStatus.TO_ACCEPT:
            return (
              <>
                <ActionButton
                  key={"accept"}
                  id={id as number}
                  actionHandler={acceptCaseModalHandler}
                  background={theme.colors.green}
                  width={52}
                  actionElement={<CheckboxCheckIcon />}
                />
                <ActionButton
                  key={"reject"}
                  id={id as number}
                  actionHandler={rejectCaseModalHandler}
                  background={theme.colors.red}
                  width={52}
                  actionElement={<CloseIcon />}
                />
              </>
            );
          case CaseStatus.SENT:
            return (
              <>
                <ActionButton
                  key={"accept"}
                  id={id as number}
                  actionHandler={acceptCaseByAccepting}
                  background={theme.colors.green}
                  width={52}
                  actionElement={<CheckboxCheckIcon />}
                />
                <ActionButton
                  key={"reject"}
                  id={id as number}
                  actionHandler={rejectCaseModalHandler}
                  background={theme.colors.red}
                  width={52}
                  actionElement={<CloseIcon />}
                />
              </>
            );
        }
      }
    }
    return null;
  };

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: "send",
        background: "white",
        actionElement: generatePublicationStatusIcon,
        width: 52,
        isDisabled: true,
      },
      {
        key: "history",
        background: generateHistoryBackground,
        actionElement: generateHistoryIcon,
        actionHandler: navigateHistoryHandler,
        width: 52,
        isDisabled: generateHistoryDisabled,
      },
      {
        key: "view",
        background: theme.colors.blue2,
        actionHandler: viewHandler,
        actionElement: <ViewLensIcon />,
        width: 52,
      },
      {
        key: "status",
        custom: generateStatusActions,
        width: 104,
      },
    ];
    return actions;
  };

  const headCells: IHeadCell[] = [
    { id: "title", numeric: false, label: "Nazwa opisu przypadku" },
    { id: "customId", numeric: false, label: "Id opisu" },
    { id: "doctor", numeric: false, label: "Autor" },
    { id: "form", numeric: false, label: "Formularz" },
    {
      id: "sentToAcceptAt",
      numeric: false,
      label: "Data przesłania do akceptacji",
    },
  ];

  const cellsSwitcher = (
    keyName: string,
    value: string | IForm,
    onClick?: () => void
  ) => {
    const specialValue: SplitData =
      keyName === "sentToAcceptAt" ? splitData(value.toString()) : ["-", ""];

    switch (keyName) {
      case "id":
        return null;
      case "sentToAcceptAt":
        return (
          <TableCellData>
            <CellTime specialValue={specialValue} />
          </TableCellData>
        );
      case "form":
        return <TableCellData>{(value as IForm).title}</TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const changeStatus = (selectedStatus: SelectedStatus) => {
    let status: CaseStatus | "" = "";

    if (selectedStatus === "Do rozpatrzenia") {
      status =
        roleFunction === RoleFunction.ROLE_ACCEPTING
          ? CaseStatus.SENT
          : CaseStatus.TO_ACCEPT;
    } else if (selectedStatus === "Zaakceptowany") {
      status = CaseStatus.ACCEPT;
    } else if (selectedStatus === "Do edycji") {
      status = CaseStatus.REJECT;
    }

    setSelectedStatus(status);
  };

  const convertValue = (caseStatus: CaseStatus | ""): SelectedStatus => {
    if (caseStatus === CaseStatus.SENT || caseStatus === CaseStatus.TO_ACCEPT)
      return "Do rozpatrzenia";
    else if (caseStatus === CaseStatus.ACCEPT) return "Zaakceptowany";
    else if (caseStatus === CaseStatus.REJECT) return "Do edycji";
    return "Status";
  };

  const addedContent = (
    <Grid container gap={2}>
      <Grid item width={"300px"}>
        <SelectString
          colorLabel={theme.colors.grey1}
          borderColor={theme.colors.grey1}
          background={"white"}
          defaultValue={"Status"}
          elementName={"status"}
          selectValue={convertValue(selectedStatus)}
          handleChange={(e) => changeStatus(e.target.value as SelectedStatus)}
          optionsArray={["Do rozpatrzenia", "Zaakceptowany", "Do edycji"]}
        />
      </Grid>
      <Grid item width={"300px"}>
        <SelectString
          colorLabel={theme.colors.grey1}
          borderColor={theme.colors.grey1}
          background={"white"}
          defaultValue={"Formularz"}
          elementName={"area"}
          selectValue={selectedForm}
          handleChange={(e) => setSelectedForm(e.target.value)}
          optionsArray={
            forms
              ? forms.map((form) => {
                  const option = {
                    id: String(form.id),
                    title: form.title,
                  } as IOptionString;
                  return option;
                })
              : []
          }
        />
      </Grid>
    </Grid>
  );

  const filteredData = filterCases(cases, selectedStatus, selectedForm);
  const tableDataRows = createTableRows(filteredData ?? [], headCells, false);

  return {
    casesIsNotNull: cases !== null,
    cellsSwitcher,
    generateActions,
    acceptCaseHandler,
    editStatusCaseHandler,
    acceptCaseModalAcceptingStart,
    rejectCaseHandler,
    addedContent,
    headCells,
    tableDataRows,
    modalType,
    isModalOpened,
    closeModal,
    isPublic,
    roleFunction,
  };
};
