// hooks
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  useCaseSettingsStore,
  useHivStore,
  useRzsStore,
} from "../../../../store/hooks";
import { useCaseApi } from "../../../../apiHooks";

// components
import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { ProgressBar } from "./progressBar/ProgressBar";
import { Grid } from "@mui/material";
import { CaseCreator } from "./caseCreator/CaseCreator";
import { EnAreaNames, EnFormNames } from "../../../../store/caseSettingsStore";
import { SaveDraftBtn } from "./saveDraftBtn/SaveDraftBtn";
import { observer } from "mobx-react";

export const DraftCase = observer(() => {
  const { area, form, caseId } = useParams();
  const location = useLocation();
  const caseSettingsStore = useCaseSettingsStore();
  const hivStore = useHivStore();
  const rzsStore = useRzsStore();

  const caseApi = useCaseApi();

  const isValidArea = Object.values(EnAreaNames).includes(area as EnAreaNames);
  const isValidForm = Object.values(EnFormNames).includes(form as EnFormNames);

  useEffect(() => {
    return () => {
      caseSettingsStore.reset();
    };
  }, [caseSettingsStore]);

  useEffect(() => {
    return () => {
      hivStore.resetCase();
    };
  }, [hivStore]);

  useEffect(() => {
    return () => {
      rzsStore.resetCase();
    };
  }, [rzsStore]);

  useEffect(() => {
    if (!area || !form || !isValidArea || !isValidForm) return;

    const isNewCase = location.pathname.includes("add");

    const currentArea = area as EnAreaNames;
    const currentForm = form as EnFormNames;
    const currentStep = 1;

    if (isNewCase) {
      caseSettingsStore.setCurrentArea(currentArea);
      caseSettingsStore.setCurrentForm(currentForm);
      caseSettingsStore.setCurrentStage(currentStep);
      caseSettingsStore.setStageCount(currentArea, currentForm);
    }

    if (caseId) {
      void caseApi.getCase({ area: currentArea, form: currentForm, caseId });
    }
  }, [
    isValidArea,
    isValidForm,
    area,
    form,
    caseId,
    caseSettingsStore,
    location,
    caseApi,
  ]);

  if (!isValidArea || !isValidForm) {
    return (
      <UserLayout>
        <Grid container>Invalid URL</Grid>
      </UserLayout>
    );
  }

  return (
    <UserLayout>
      <Grid container position={"relative"} justifyContent={"center"}>
        <ProgressBar />
        <Grid container mt={4} flexDirection={"row"}>
          <Grid item xs={10}>
            <CaseCreator />
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent={"center"} position={"relative"}>
              <SaveDraftBtn />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </UserLayout>
  );
});
