import { observer } from "mobx-react";
// hooks
import { useCaseSettingsStore } from "../../../../../store/hooks";
// components
import { EnFormNames } from "../../../../../store/caseSettingsStore";
import { HivForm } from "../forms/virusology/hiv/HivForm";
import { RzsForm } from "../forms/rheumatology/rzs/RzsForm";

export const CaseCreator = observer(() => {
  const { currentForm } = useCaseSettingsStore();

  if (currentForm === EnFormNames.hiv) {
    return <HivForm />;
  }

  if (currentForm === EnFormNames.rzs) {
    return <RzsForm />;
  }

  return null;
});
