import {observer} from "mobx-react";
import React from "react";

// hooks
import {useRzsStore} from "../../../../../../../../store/hooks";

// components
import {Grid} from "@mui/material";
import {Label, NormalText} from "../../../virusology/hiv/HivViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hiv/HivViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";

export const CurrentTreatmentSection = observer(() => {
  const caseStore = useRzsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={5} bgcolor={"white"} p={2}>
        <Grid item xs={12} mb={3}>
          <Label $size={"large"} $mb={0}>
            DOTYCHCZASOWE LECZENIE
          </Label>
        </Grid>
        <Grid container ml={2}>
          <Grid item xs={4}>
            <Label>DATA ROZPOZNANIA</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.diagnosisDate))}
            </NormalText>
          </Grid>
          <Grid item xs={4}>
            <Label>WYNIK DAS 28</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.das28Result)}
            </NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>WYNIK</Label>
            <NormalText>{checkValueIsEmpty(caseElement.result)}</NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>WYNIK VAS</Label>
            <NormalText>{checkValueIsEmpty(caseElement.vasResult)}</NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>
              WYNIK {caseElement.das28Result === "crp" ? "CRP" : "OB"}
            </Label>
            <NormalText>
              {checkValueIsEmpty(
                caseElement.das28Result === "crp"
                  ? caseElement.crpResult
                  : caseElement.obResult
              )}
            </NormalText>
          </Grid>
          <Grid item xs={4} mt={2}></Grid>
          <Grid item xs={4} mt={2}>
            <Label>LICZBA BOLESNYCH STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.tenderJointsCount)}
            </NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.swollenJointsCount)}
            </NormalText>
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={5} bgcolor={"white"} p={2}>
        {caseElement.drugs.map((drug, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} mb={3}>
              <Label $size={"large"} $mb={0}>
                {`Leczenie ${index + 1}`}
              </Label>
            </Grid>
            <Grid container ml={2} mb={2}>
              <Grid item xs={6}>
                <Label>LECZENIE</Label>
                <NormalText>{checkValueIsEmpty(drug.treatment)}</NormalText>
              </Grid>
              <Grid item xs={6}>
                <Label>GLIKOKORTYKOSTEROIDY (GKS)</Label>
                <NormalText>{checkValueIsEmpty(drug.gks)}</NormalText>
              </Grid>
              <Grid item xs={6} mt={2}>
                <Label>DAWKA</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.dose)}{" "}
                  {checkValueIsEmpty(drug.unit === 'Inna jednostka' ? drug.otherUnit : drug.unit)}
                </NormalText>
              </Grid>
              <Grid item xs={6} mt={2}>
                <Label>ZAKRES CZASU PRZYJMOWANIA</Label>
                <NormalText>{checkValueIsEmpty(drug.doseRange)}</NormalText>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
});
