import { observer } from "mobx-react-lite";

// types
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../store/caseSettingsStore";

// hooks
import { useHivStore } from "../../../../../../../store/hooks";

// components
import { TopViewCase } from "../../topViewCase/TopViewCase";
import { PatientSection } from "./patientSection/PatientSection";
import { DiagnosisSection } from "./diagnosisSection/DiagnosisSection";
import { QualificationSection } from "./qualificationSection/QualificationSection";
import { TreatmentSection } from "./treatmentSection/TreatmentSection";
import { UserLayout } from "../../../../../../shared/user/layout/UserLayout";
import { useViewCase } from "../../../viewCaseCreator/useViewCase";

export const HivViewCase = observer(() => {
  const hivStore = useHivStore();
  const { isCase } = useViewCase(
    EnAreaNames.virusology,
    EnFormNames.hiv,
    hivStore
  );

  return (
    <UserLayout>
      {isCase && (
        <>
          <TopViewCase caseStore={hivStore} />
          <PatientSection />
          <DiagnosisSection />
          <QualificationSection />
          <TreatmentSection />
        </>
      )}
    </UserLayout>
  );
});
