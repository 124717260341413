import {observer} from "mobx-react";

// hooks
import {useRzsStore} from "../../../../../../../../store/hooks";

// components
import {Grid} from "@mui/material";
import {Label, NormalText} from "../../../virusology/hiv/HivViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hiv/HivViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import React from "react";
import {Attachments} from "../../../virusology/hiv/common/attachments/Attachments";

export const Observations = observer(() => {
  const caseStore = useRzsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={5} bgcolor={"white"} p={2}>
        <Grid item xs={12} mb={3}>
          <Label $size={"large"} $mb={0}>
            Wyniki badań przed włączeniem leku Rinvoq
          </Label>
        </Grid>
        <Grid container ml={2}>
          <Grid item xs={4}>
            <Label>Data włączenia leku</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.programEntryDate))}
            </NormalText>
          </Grid>
          <Grid item xs={4}>
            <Label>WYNIK DAS 28</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.das28Result3)}
            </NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>WYNIK</Label>
            <NormalText>{checkValueIsEmpty(caseElement.result3)}</NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>WYNIK VAS</Label>
            <NormalText>{checkValueIsEmpty(caseElement.vasResult3)}</NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>
              WYNIK {caseElement.das28Result3 === "crp" ? "CRP" : "OB"}*
            </Label>
            <NormalText>
              {checkValueIsEmpty(
                caseElement.das28Result3 === "crp"
                  ? caseElement.crpResult3
                  : caseElement.obResult3
              )}
            </NormalText>
          </Grid>
          <Grid item xs={4} mt={2}></Grid>
          <Grid item xs={4} mt={2}>
            <Label>LICZBA BOLESNYCH STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.tenderJointsCount3)}
            </NormalText>
          </Grid>
          <Grid item xs={4} mt={2}>
            <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.swollenJointsCount3)}
            </NormalText>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Label>Obserwacja na leku Rinvoq</Label>
          <NormalText>
            {checkValueIsEmpty(caseElement.observationIntervals)}
          </NormalText>
        </Grid>

        <Grid container mt={5} bgcolor={"white"} p={2}>
          {caseElement.observations.map((observation, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} mb={3}>
                <Label $size={"large"} $mb={0}>
                  {`Obserwacja ${index + 1}`}
                </Label>
              </Grid>
              <Grid container ml={2} mb={2}>
                <Grid item xs={12}>
                  <Label>Monoterapia czy kombinacja z innym lekiem?</Label>
                  <NormalText>{checkValueIsEmpty(observation.monotherapy)}</NormalText>
                </Grid>

                <Grid item xs={12}>

                  KOMBINACJE 1,2,3

                </Grid>

                <Grid item xs={4}>
                  <Label>WYNIK DAS 28</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.das28Result)}
                  </NormalText>
                </Grid>
                <Grid item xs={4} mt={2}>
                  <Label>WYNIK</Label>
                  <NormalText>{checkValueIsEmpty(observation.result)}</NormalText>
                </Grid>
                <Grid item xs={4} mt={2}>
                  <Label>WYNIK VAS</Label>
                  <NormalText>{checkValueIsEmpty(observation.vasResult)}</NormalText>
                </Grid>
                <Grid item xs={4} mt={2}>
                  <Label>
                    WYNIK {observation.das28Result === "crp" ? "CRP" : "OB"}*
                  </Label>
                  <NormalText>
                    {checkValueIsEmpty(
                      observation.das28Result === "crp"
                        ? observation.crpResult
                        : observation.obResult
                    )}
                  </NormalText>
                </Grid>
                <Grid item xs={4} mt={2}></Grid>
                <Grid item xs={4} mt={2}>
                  <Label>LICZBA BOLESNYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.tenderJointsCount)}
                  </NormalText>
                </Grid>
                <Grid item xs={4} mt={2}>
                  <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.swollenJointsCount)}
                  </NormalText>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Label>Glikokortykosteroidy (GKS)</Label>
                  <NormalText>{checkValueIsEmpty(observation.gks)}</NormalText>
                </Grid>

              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid container mt={5} bgcolor={"white"} p={2}>
        <Grid item xs={12} mb={3}>
          <Label $size={"large"} $mb={0}>
            WNIOSKI
          </Label>
        </Grid>
        <Grid container ml={2} mb={2}>
          <Grid item xs={12}>
            <NormalText>
              {checkValueIsEmpty(caseElement.conclusions)}
            </NormalText>
          </Grid>
        </Grid>
        {caseElement.rzsFiles &&
          caseElement.rzsFiles.length > 0 && (
            <Grid item xs={12}>
              <Attachments
                attachments={
                  caseElement.rzsFiles
                }
              />
            </Grid>
          )}
      </Grid>
    </>
  );
});
