import { Grid } from "@mui/material";
import { GroupForm, Label, NormalText } from "../HivViewCase.styled";
import { Attachments } from "../common/attachments/Attachments";

import { checkValueIsEmpty } from "../HivViewCase.helper";
import { useHivStore } from "../../../../../../../../store/hooks";

export const TreatmentSection = () => {
  const caseStore = useHivStore();
  const { case: caseElement } = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5} mb={7}>
      <Grid item xs={12} mb={"36px"}>
        <GroupForm>PRZEBIEG LECZENIA:</GroupForm>
      </Grid>

      <Grid item xs={12}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={3}>
            <Label>INFORMACJE O PRZEBIEGU TERAPII</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.infoAboutTherapy)}
            </NormalText>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Label>WNIOSKI</Label>
            <NormalText>{checkValueIsEmpty(caseElement.conclusion)}</NormalText>
          </Grid>
          {caseElement.abdominalUltrasoundAfterTreatmentFiles &&
            caseElement.abdominalUltrasoundAfterTreatmentFiles.length > 0 && (
              <Grid item xs={12}>
                <Attachments
                  attachments={
                    caseElement.abdominalUltrasoundAfterTreatmentFiles
                  }
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};
