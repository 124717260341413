import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

// hooks
import {
  useCaseSettingsStore,
  useHivStore,
} from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";

// types
import { THivStage03 } from "./type";

// lib
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";

export const validationSchema = yup.object().shape({
  rbc1: yup.string(),
  hgb1: yup.string(),
  htc1: yup.string(),
  plt1: yup.string(),
  wbc1: yup.string(),
  mcv1: yup.string(),
  mch1: yup.string(),
  mchc1: yup.string(),
  hcvRna1: yup.string(),
  aiat1: yup.string(),
  albumin1: yup.string(),
  bilirubin1: yup.string(),
  simplifiedDiagram: yup.string().required(),
  durationOfTherapy: yup.string().required(),
  rbc4: yup.string(),
  hgb4: yup.string(),
  htc4: yup.string(),
  plt4: yup.string(),
  wbc4: yup.string(),
  mcv4: yup.string(),
  mch4: yup.string(),
  mchc4: yup.string(),
  aiat4: yup.string(),
  bilirubin4: yup.string(),
  bilirubinMonitoring4: yup.string(),
  rbc8: yup.string(),
  hgb8: yup.string(),
  htc8: yup.string(),
  plt8: yup.string(),
  wbc8: yup.string(),
  mcv8: yup.string(),
  mch8: yup.string(),
  mchc8: yup.string(),
  aiat8: yup.string(),
  bilirubin8: yup.string(),
  bilirubinMonitoring8: yup
    .string()
    .nullable()
    .test(
      "isSelectedbilirubinMonitoring8",
      "Pole bilirubinMonitoring8 jest wymagane, gdy isSelectedbilirubinMonitoring4 jest tak",
      function (value) {
        const bilirubinMonitoring4 = this.parent.bilirubinMonitoring4;
        const durationOfTherapy = this.parent.durationOfTherapy;
        if (
          bilirubinMonitoring4 === "tak" &&
          (durationOfTherapy === "12 tygodni" ||
            durationOfTherapy === "16 tygodni")
        ) {
          return !!value;
        }
        return true;
      }
    ),
  rbc12: yup.string(),
  hgb12: yup.string(),
  htc12: yup.string(),
  plt12: yup.string(),
  wbc12: yup.string(),
  mcv12: yup.string(),
  mch12: yup.string(),
  mchc12: yup.string(),
  aiat12: yup.string(),
  bilirubin12: yup.string(),
  bilirubinMonitoring12: yup
    .string()
    .nullable()
    .test(
      "isSelectedbilirubinMonitoring12",
      "Pole bilirubinMonitoring12 jest wymagane, gdy isSelectedbilirubinMonitoring8 jest tak",
      function (value) {
        const bilirubinMonitoring8 = this.parent.bilirubinMonitoring8;
        const durationOfTherapy = this.parent.durationOfTherapy;
        if (
          bilirubinMonitoring8 === "tak" &&
          durationOfTherapy === "16 tygodni"
        ) {
          return !!value;
        }
        return true;
      }
    ),
  hcvRnaAfterTreatment: yup.string(),
  hcvRnaAfterTreatmentDesc: yup
    .string()
    .nullable()
    .test(
      "ishcvRnaAfterTreatment",
      "Pole hcvRnaAfterTreatmentDesc jest wymagane, gdy hcvRnaAfterTreatment jest wykrywalne.",
      function (value) {
        const hcvRnaAfterTreatment = this.parent.hcvRnaAfterTreatment;
        if (hcvRnaAfterTreatment === "wykrywalne") {
          return !!value;
        }
        return true;
      }
    ),
  aiatAfterTreatment: yup.string(),
  abdominalUltrasoundAfterTreatment: yup.string(),
  abdominalUltrasoundAfterTreatmentCheckbox: yup.boolean(),
  hcvRnaAfterTreatment12: yup.string().required(),
  abdominalUltrasoundAfterTreatment12: yup.string().required(),
});

const initialValues: THivStage03 = {
  rbc1: "",
  hgb1: "",
  htc1: "",
  plt1: "",
  wbc1: "",
  mcv1: "",
  mch1: "",
  mchc1: "",
  hcvRna1: "",
  aiat1: "",
  albumin1: "",
  bilirubin1: "",
  simplifiedDiagram: "",
  durationOfTherapy: "",
  rbc4: "",
  hgb4: "",
  htc4: "",
  plt4: "",
  wbc4: "",
  mcv4: "",
  mch4: "",
  mchc4: "",
  aiat4: "",
  bilirubin4: "",
  bilirubinMonitoring4: "",
  rbc8: "",
  hgb8: "",
  htc8: "",
  plt8: "",
  wbc8: "",
  mcv8: "",
  mch8: "",
  mchc8: "",
  aiat8: "",
  bilirubin8: "",
  bilirubinMonitoring8: "",
  rbc12: "",
  hgb12: "",
  htc12: "",
  plt12: "",
  wbc12: "",
  mcv12: "",
  mch12: "",
  mchc12: "",
  aiat12: "",
  bilirubin12: "",
  bilirubinMonitoring12: "",
  hcvRnaAfterTreatment: "",
  hcvRnaAfterTreatmentDesc: "",
  aiatAfterTreatment: "",
  abdominalUltrasoundAfterTreatment: "",
  abdominalUltrasoundAfterTreatmentCheckbox: false,
  hcvRnaAfterTreatment12: "",
  abdominalUltrasoundAfterTreatment12: "",
  abdominalUltrasoundAfterTreatmentCheckbox12: false,
};
export const useThirdStageVm = () => {
  // params and location
  const params = useParams();
  const { area, form, caseId } = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {
    isNextButtonClicked,
    isPrevButtonClicked,
    currentStage,
    statusDraftButtonClicked,
  } = caseSettingsStore;

  // rzs store
  const hivStore = useHivStore();
  const { thirdStage, caseStage } = hivStore;

  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(thirdStage))
      : JSON.parse(JSON.stringify(initialValues));

  // define API calls
  const {
    postAbdominalFileAfterTreatmentThirdStage,
    deleteAbdominalFileAfterTreatmentThirdStage,
    postAbdominalFileAfterTreatment12ThirdStage,
    deleteAbdominalFileAfterTreatment12ThirdStage,
    updateCase,
  } = useCaseApi();

  const formik = useFormik<THivStage03>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: THivStage03) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );

      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false
  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await formik.submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  useEffect(() => {
    if (statusDraftButtonClicked === "click" && hivStore.case !== null) {
      hivStore.setCase({
        ...hivStore.case,
        ...formik.values,
      });
      caseSettingsStore.saveStageDraftButton();
    }
  }, [statusDraftButtonClicked, formik.values, hivStore, caseSettingsStore]);

  // handlers abdominalUltrasoundAfterTreatment file
  const addAbdominalUltrasoundAfterTreatmentFile = async (
    file: File | null
  ) => {
    if (file && caseId) {
      await postAbdominalFileAfterTreatmentThirdStage(+caseId, file);
    }
  };

  const removeAbdominalUltrasoundAfterTreatmentFile = async (
    idFile: number | string
  ) => {
    if (idFile && caseId) {
      await deleteAbdominalFileAfterTreatmentThirdStage(+caseId, idFile);
    }
  };

  const addAbdominalUltrasoundAfterTreatmentFile12 = async (
    file: File | null
  ) => {
    if (file && caseId) {
      await postAbdominalFileAfterTreatment12ThirdStage(+caseId, file);
    }
  };

  const removeAbdominalUltrasoundAfterTreatmentFile12 = async (
    idFile: number | string
  ) => {
    if (idFile && caseId) {
      await deleteAbdominalFileAfterTreatment12ThirdStage(+caseId, idFile);
    }
  };

  return {
    formik,
    abdominalUltrasoundAfterTreatmentFiles:
      thirdStage && thirdStage.abdominalUltrasoundAfterTreatmentFiles
        ? thirdStage.abdominalUltrasoundAfterTreatmentFiles
        : [],
    abdominalUltrasoundAfterTreatmentFiles12:
      thirdStage && thirdStage.abdominalUltrasoundAfterTreatmentFiles12
        ? thirdStage.abdominalUltrasoundAfterTreatmentFiles12
        : [],
    addAbdominalUltrasoundAfterTreatmentFile,
    removeAbdominalUltrasoundAfterTreatmentFile,
    addAbdominalUltrasoundAfterTreatmentFile12,
    removeAbdominalUltrasoundAfterTreatmentFile12,
  };
};
