import { AxiosRequestConfig } from "axios";

// hooks
import { useCallback } from "react";
import { useApiCall } from "./useApiCall";
import {
  useCaseSettingsStore,
  useHistoryStore,
  useHivStore,
  useProgressBarStore,
  useRzsStore,
} from "../store/hooks";

// types
import { TRzsStage01 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage01/type";

// interfaces
import { IFirstStage } from "../interfaces/user/draftCase/firstStage";
import { ISecondStage } from "../interfaces/user/draftCase/secondStage";
import { IFourthStage } from "../interfaces/user/draftCase/fouthStage";
import { IThirdStage } from "../interfaces/user/draftCase/thirdStage";
import { IFile } from "../interfaces/user/draftCase/file";

import { EnAreaNames, EnFormNames } from "../store/caseSettingsStore";
import { THivStage01 } from "../components/pages/user/draftCase/forms/virusology/hiv/stages/stage01/type";
import { THiv } from "../components/pages/user/draftCase/forms/virusology/hiv/type";
import { TRzs } from "../components/pages/user/draftCase/forms/rheumatology/rzs/type";
import { TRzsStage02 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage02/type";
import { TRzsStage03 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage03/type";
import { TRzsStage04 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage04/type";
import { TRzsStage05 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/Stage05/type";
import { THivStage02 } from "../components/pages/user/draftCase/forms/virusology/hiv/stages/stage02/type";
import { THivStage03 } from "../components/pages/user/draftCase/forms/virusology/hiv/stages/stage03/type";
import { THivStage04 } from "../components/pages/user/draftCase/forms/virusology/hiv/stages/stage04/type";

export const useCaseApi = () => {
  const { api } = useApiCall();
  // const caseStore = useCaseStore();
  const progressBarStore = useProgressBarStore();
  const historyStore = useHistoryStore();
  const caseSettingsStore = useCaseSettingsStore();
  const hivStore = useHivStore();
  const rzsStore = useRzsStore();

  const getCase = useCallback(
    async ({
      area,
      form,
      caseId,
    }: {
      area: EnAreaNames;
      form: EnFormNames;
      caseId: string;
    }) => {
      const res: THiv | TRzs = await api.apiInstance.get(
        `situation/${area}/${form}/${caseId}`
      );

      if (!res?.step) {
        throw new Error("Case step not found");
      }

      // check if current area, form, stage and stage count exist
      const isCurrentAreaExist = caseSettingsStore.currentArea;

      if (!isCurrentAreaExist) {
        caseSettingsStore.setCurrentArea(area);
      }

      const isCurrentFormExist = caseSettingsStore.currentForm;

      if (!isCurrentFormExist) {
        caseSettingsStore.setCurrentForm(form);
      }

      const isCurrentStageExist = caseSettingsStore.currentStage;

      if (!isCurrentStageExist) {
        caseSettingsStore.setCurrentStage(res.step);
      }

      const isStageCountExist = caseSettingsStore.stageCount;

      if (!isStageCountExist) {
        caseSettingsStore.setStageCount(area, form);
      }

      // set form data
      if (form === EnFormNames.hiv) {
        hivStore.setCase(res as THiv);
      }

      if (form === EnFormNames.rzs) {
        rzsStore.setCase(res as TRzs);
      }
    },
    [api.apiInstance, caseSettingsStore, hivStore, rzsStore]
  );

  // create new case
  const createNewCase = useCallback(
    async (
      value: TRzsStage01 | THivStage01,
      area: EnAreaNames,
      form: EnFormNames
    ) => {
      const res = await api.apiInstance.post(
        `situation/${area}/${form}`,
        value
      );

      return res?.id;
    },
    [api.apiInstance]
  );

  // TODO refactor this function?
  type TUniversalStage =
    | TRzsStage01
    | TRzsStage02
    | TRzsStage03
    | TRzsStage04
    | TRzsStage05
    | THivStage01
    | THivStage02
    | THivStage03
    | THivStage04;

  const updateCase = useCallback(
    async ({
      value,
      area,
      form,
      caseId,
      stage,
    }: {
      value: TUniversalStage;
      area: EnAreaNames;
      form: EnFormNames;
      caseId: string;
      stage: number;
    }) => {
      if (!area || !form || !caseId || !stage) {
        throw new Error(
          "In editCase function some of the arguments are missing"
        );
      }
      const res = await api.apiInstance.put(
        `situation/${area}/${form}/${caseId}/${stage}`,
        value
      );

      if (res) {
        await getCase({ area, form, caseId });
        caseSettingsStore.increaseCurrentStage();
      }

      throw new Error("Edit stage api, some rules are broken");
    },

    [api.apiInstance, caseSettingsStore, getCase]
  );

  const putFirstStageDraftCase = useCallback(
    async (id: number, firstStage: IFirstStage) => {
      const res = await api.apiInstance.put(
        `situation/${id}/first`,
        firstStage
      );
      return !!res;
    },
    [api.apiInstance]
  );

  const putSecondStageDraftCase = useCallback(
    async (id: number, secondStage: ISecondStage) => {
      const res = await api.apiInstance.put(
        `situation/${id}/second`,
        secondStage
      );
      return !!res;
    },
    [api.apiInstance]
  );

  const putThirdStageDraftCase = useCallback(
    async (id: number, fourthStage: IThirdStage) => {
      const res = await api.apiInstance.put(
        `situation/${id}/third`,
        fourthStage
      );
      return !!res;
    },
    [api.apiInstance]
  );

  const putFourthStageDraftCase = useCallback(
    async (id: number, fourthStage: IFourthStage) => {
      const res = await api.apiInstance.put(
        `situation/${id}/fourth`,
        fourthStage
      );
      return !!res;
    },
    [api.apiInstance]
  );

  // TODO refactor this functions
  // HIV functions
  // post abdominalFiles
  const postAbdominalFilesSecond = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/abdominalFile/second`,
        form,
        true,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({
            ...progressBarStore.secondStage,
            abdominalUltrasoundFiles: res,
          });
        }

        void getCase({
          area: EnAreaNames.virusology,
          form: EnFormNames.hiv,
          caseId: String(id),
        });
      }
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // delete abdominalFiles
  const deleteAbdominalFilesSecond = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/abdominalFile/${idFile}/second`
      );
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({
            ...progressBarStore.secondStage,
            abdominalUltrasoundFiles: res,
          });
        }
      }

      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  const postFileFifthStage = useCallback(
    async (id: number, file: File, callback: (res: IFile[]) => void) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/rheumatology/${id}/file/fifth`,
        form,
        true,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res) {
        callback(res);
      }

      await getCase({
        area: EnAreaNames.rheumatology,
        form: EnFormNames.rzs,
        caseId: String(id),
      });
    },
    [api.apiInstance, getCase]
  );

  // delete abdominalFiles
  const deleteFileFifthStage = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[]) => void
    ) => {
      const res = await api.apiInstance.delete(
        `situation/rheumatology/${id}/file/${idFile}/fifth`
      );
      if (res) {
        callback(res);
      }

      await getCase({
        area: EnAreaNames.rheumatology,
        form: EnFormNames.rzs,
        caseId: String(id),
      });
    },
    [api.apiInstance, getCase]
  );

  // post drugInteractionFiles
  const postDrugInteractionFilesSecond = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/potentialDrugInteractionFile/second`,
        form,
        true,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({
            ...progressBarStore.secondStage,
            potentialDrugInteractionFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // dlete drugInteractionFiles
  const deleteDrugInteractionFilesSecond = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/potentialDrugInteractionFile/${idFile}/second`
      );
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({
            ...progressBarStore.secondStage,
            potentialDrugInteractionFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // post liverFiles
  const postLiverFilesSecondStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/liverFile/second`,
        form,
        true,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({
            ...progressBarStore.secondStage,
            liverBiopsyFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // delete liverFiles
  const deleteLiverFilesSecondStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/liverFile/${idFile}/second`
      );
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({
            ...progressBarStore.secondStage,
            liverBiopsyFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // post abdominalFiles after treatment
  const postAbdominalFileAfterTreatmentThirdStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/abdominalFile/third`,
        form,
        true,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({
            ...progressBarStore.thirdStage,
            abdominalUltrasoundAfterTreatmentFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // delete abdominalFiles after treatment
  const deleteAbdominalFileAfterTreatmentThirdStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/abdominalFile/${idFile}/third`
      );
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({
            ...progressBarStore.thirdStage,
            abdominalUltrasoundAfterTreatmentFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // post abdominalFiles after treatment
  const postAbdominalFileAfterTreatment12ThirdStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/abdominalFile12/third`,
        form,
        true,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({
            ...progressBarStore.thirdStage,
            abdominalUltrasoundAfterTreatmentFiles12: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // delete abdominalFiles after treatment
  const deleteAbdominalFileAfterTreatment12ThirdStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/abdominalFile12/${idFile}/third`
      );
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({
            ...progressBarStore.thirdStage,
            abdominalUltrasoundAfterTreatmentFiles12: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // post conclusionFiles
  const postConclusionFileFourthStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/conclusionFile/fourth`,
        form,
        true,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res) {
        if (progressBarStore.fourthStage) {
          progressBarStore.setFourthStage({
            ...progressBarStore.fourthStage,
            conclusionFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  // delete conclusionFiles
  const deleteConclusionFileFourthStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/conclusionFile/${idFile}/fourth`
      );
      if (res) {
        if (progressBarStore.fourthStage) {
          progressBarStore.setFourthStage({
            ...progressBarStore.fourthStage,
            conclusionFiles: res,
          });
        }
      }
      void getCase({
        area: EnAreaNames.virusology,
        form: EnFormNames.hiv,
        caseId: String(id),
      });
    },
    [api.apiInstance, progressBarStore, getCase]
  );

  const publishDraftCase = useCallback(
    async ({
      area,
      form,
      caseId,
    }: {
      area: EnAreaNames;
      form: EnFormNames;
      caseId: string;
    }) => {
      const res = await api.apiInstance.post(
        `situation-flow/${area}/${form}/${caseId}/send-to-accept`,
        {}
      );
      return !!res;
    },
    [api.apiInstance]
  );

  const getCaseComments = useCallback(
    async (id: number, isLoading: boolean = true) => {
      const res = await api.apiInstance.get(
        `situation/${id}/comments`,
        isLoading
      );
      historyStore.setHistory(res);
      return !!res;
    },
    [historyStore, api.apiInstance]
  );

  const getCasePDF = useCallback(
    async (id: number, area: EnAreaNames, form: EnFormNames) => {
      // TODO: common method for every forms.

      const config: AxiosRequestConfig = { responseType: "blob" };
      return await api.apiInstance.get(`situation/${id}/zip`, true, config);
    },
    [api.apiInstance]
  );

  const saveDraft = useCallback(
    async ({
      values,
      caseId,
      area,
      form,
    }: {
      values: THiv | TRzs;
      caseId: number;
      area: EnAreaNames;
      form: EnFormNames;
    }) => {
      const res = await api.apiInstance.put(
        `situation-flow/${area}/${form}/${caseId}/draft`,
        values,
        true
      );
      if (res) {
        return !!res;
      }
    },
    [api.apiInstance]
  );

  const sendQuestionEmail = useCallback(
    async (
      id: number,
      questionObj: any,
      area: EnAreaNames,
      form: EnFormNames
    ) => {
      // TODO: common method for every forms.
      const res = await api.apiInstance.post(
        `situation/${id}/email`,
        questionObj,
        true
      );
      if (res) {
        return !!res;
      }
    },
    [api.apiInstance]
  );

  return {
    getCasePDF,
    putFirstStageDraftCase,
    postAbdominalFilesSecond,
    deleteAbdominalFilesSecond,
    postLiverFilesSecondStage,
    deleteLiverFilesSecondStage,
    putSecondStageDraftCase,
    putThirdStageDraftCase,
    putFourthStageDraftCase,
    postAbdominalFileAfterTreatmentThirdStage,
    deleteAbdominalFileAfterTreatmentThirdStage,
    postConclusionFileFourthStage,
    deleteConclusionFileFourthStage,
    publishDraftCase,
    getCaseComments,
    deleteAbdominalFileAfterTreatment12ThirdStage,
    postAbdominalFileAfterTreatment12ThirdStage,
    saveDraft,
    sendQuestionEmail,
    deleteDrugInteractionFilesSecond,
    postDrugInteractionFilesSecond,
    // new architecture
    createNewCase,
    getCase,
    updateCase,
    deleteFileFifthStage,
    postFileFifthStage,
  };
};
