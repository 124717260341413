import { useRootStore } from "../../RootStateContext";

export const useHivStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.hivStore) {
    throw new Error("hivStore store should be defined");
  }

  return rootStore.hivStore;
};
