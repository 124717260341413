import { TStage } from "../../../../../../../interfaces/user/draftCase/progressBar";

export const hivStages: TStage[] = [
  {
    stage: 1,
    title: "Pacjent",
    isStepper: true,
  },
  {
    stage: 2,
    title: "Rozpoznanie",
    isStepper: true,
  },
  {
    stage: 3,
    title: "Kwalifikacja",
    isStepper: true,
  },
  {
    stage: 4,
    title: "Przebieg leczenia",
    isStepper: true,
  },
  {
    stage: 5,
    title: "Gratulacje!",
    isStepper: false,
  },
];
