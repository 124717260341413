import * as yup from "yup";

// hooks
import { useEffect } from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  useCaseSettingsStore,
  useRzsStore,
} from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";

// types
import { TRzsStage02 } from "./type";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../../../../store/caseSettingsStore";

const validationSchema = yup.object().shape({
  diagnosisDate: yup.string().required(),
  das28Result: yup.string().required(),
  result: yup.number().required(),
  vasResult: yup.number().required(),
  obResult: yup
    .number()
    .nullable()
    .when("das28Result", {
      is: (value: string) => value === "crp",
      then: (schema) => schema.required(),
    }),
  crpResult: yup
    .number()
    .nullable()
    .when("das28Result", {
      is: (value: string) => value === "ob",
      then: (schema) => schema.required(),
    }),
  tenderJointsCount: yup.number().min(0).max(10).required(),
  swollenJointsCount: yup.number().min(0).max(10).required(),
  drugs: yup
    .array()
    .of(
      yup.object().shape({
        treatment: yup.string().required(),
        gks: yup.string().required(),
        dose: yup.number().required(),
        unit: yup.string().required(),
        otherUnit: yup.string().when("unit", {
          is: (value: string) => value === "Inna jednostka",
          then: (schema) => schema.required(),
        }),
        doseRange: yup.number().required(),
      })
    )
    .min(1)
    .required(),
});

const initialValues: TRzsStage02 = {
  diagnosisDate: null,
  das28Result: "",
  result: null,
  vasResult: null,
  obResult: null,
  crpResult: null,
  tenderJointsCount: null,
  swollenJointsCount: null,
  drugs: [
    {
      treatment: "",
      gks: "",
      dose: null,
      unit: "",
      otherUnit: "",
      doseRange: null,
    },
  ],
};

export const useStage02ViewModel = () => {
  // params and location
  const params = useParams();
  const { area, form, caseId } = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {
    isNextButtonClicked,
    isPrevButtonClicked,
    currentStage,
    statusDraftButtonClicked,
  } = caseSettingsStore;

  // rzs store
  const rzsStore = useRzsStore();
  const { secondStage, caseStage } = rzsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(secondStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TRzsStage02>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false

  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await formik.submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  useEffect(() => {
    if (statusDraftButtonClicked === "click" && rzsStore.case !== null) {
      rzsStore.setCase({
        ...rzsStore.case,
        ...formik.values,
      });
      caseSettingsStore.saveStageDraftButton();
    }
  }, [statusDraftButtonClicked, formik.values, rzsStore, caseSettingsStore]);

  const { setFieldValue } = formik;

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    await formik.submitForm();
  };

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`drugs[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const addDrug = () => {
    setFieldValue("drugs", [
      ...formik.values.drugs,
      {
        treatment: "",
        gks: "",
        dose: null,
        unit: "",
        otherUnit: "",
        doseRange: null,
      },
    ]);
  };

  const deleteDrug = (index: number) => {
    const filteredItems = [
      ...formik.values.drugs.filter((el, idx) => idx !== index),
    ];
    setFieldValue("drugs", filteredItems);
  };

  return {
    formik,
    onSubmitHandler,
    setOption,
    addDrug,
    deleteDrug,
  };
};
