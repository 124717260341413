import { ButtonTransparent, DraftBtnContainer } from "./SaveDraftBtn.styled";
import { ReactComponent as Pen } from "../../../../../assets/icon/edit.svg";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../apiHooks";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../store/caseSettingsStore";
import { TRzs } from "../forms/rheumatology/rzs/type";
import { THiv } from "../forms/virusology/hiv/type";
import {
  useCaseSettingsStore,
  useHivStore,
  useRzsStore,
} from "../../../../../store/hooks";
import { useCallback, useEffect } from "react";

export const SaveDraftBtn = observer(() => {
  const { caseId, area, form } = useParams();
  const { saveDraft } = useCaseApi();

  const caseSettingsStore = useCaseSettingsStore();
  const { statusDraftButtonClicked } = caseSettingsStore;

  const hivStore = useHivStore();
  const rzsStore = useRzsStore();

  const saveCaseHandler = useCallback(async () => {
    if (caseId && area && form) {
      await saveDraft({
        caseId: Number(caseId),
        area: area as EnAreaNames,
        form: form as EnFormNames,
        values:
          (form as EnFormNames) === EnFormNames.hiv
            ? ({
                ...hivStore.case,
                ...hivStore.firstStage,
                ...hivStore.secondStage,
                ...hivStore.thirdStage,
                ...hivStore.fourthStage,
              } as THiv)
            : ({
                ...rzsStore.case,
                ...rzsStore.fifthStage,
                ...rzsStore.secondStage,
                ...rzsStore.thirdStage,
                ...rzsStore.fourthStage,
                ...rzsStore.fifthStage,
              } as TRzs),
      });
      caseSettingsStore.unClickDraftButton();
    }
  }, [
    area,
    caseId,
    caseSettingsStore,
    form,
    hivStore.case,
    hivStore.firstStage,
    hivStore.fourthStage,
    hivStore.secondStage,
    hivStore.thirdStage,
    rzsStore.case,
    rzsStore.fifthStage,
    rzsStore.fourthStage,
    rzsStore.secondStage,
    rzsStore.thirdStage,
    saveDraft,
  ]);

  useEffect(() => {
    if (statusDraftButtonClicked === "save") void saveCaseHandler();
  }, [statusDraftButtonClicked, saveCaseHandler]);

  if (!caseId) {
    return null;
  }

  return (
    <DraftBtnContainer>
      <ButtonTransparent onClick={() => caseSettingsStore.clickDraftButton()}>
        zapisz szkic
        <Pen />
      </ButtonTransparent>
    </DraftBtnContainer>
  );
});
