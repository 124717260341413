import { observer } from "mobx-react";

import { useAreas } from "./useAreas";
import { AdminLayout } from "../../../shared/admin/layout/AdminLayout";
import { CustomTable } from "../../../shared/customTables/customTable/CustomTable";
import { Button } from "../../../shared/button/Button";
import theme from "../../../../theme/theme";
import { AreasContainer, Title, TopContentContainer } from "./Areas.styled";
import { Modal } from "../../../shared/modal/Modal";
import { ModalType } from "../../../../constants/modalType";
import { DeleteModal } from "../../../shared/modal/sharedModals/deleteModal/DeleteModal";
import { ConfirmationModal } from "../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal";

export const Areas = observer(() => {
  const {
    isAreasLoaded,
    generateActions,
    headCells,
    newHandler,
    tableDataRows,
    isModalOpened,
    closeModal,
    modalType,
    deleteHandlerYes,
  } = useAreas();

  return (
    <AdminLayout>
      {isAreasLoaded && (
        <AreasContainer>
          <CustomTable
            searchBarAddedContent={
              <>
                <TopContentContainer>
                  <Button
                    clickHandler={newHandler}
                    text={"Dodaj obszar terapeutyczny +"}
                    size={"tiny"}
                    width={"270px"}
                    backgroundColor={theme.colors.white}
                    hoverColor={theme.colors.white}
                    fontWeight={700}
                    color={theme.colors.abbvie}
                  />
                </TopContentContainer>
                <Title>Obszary terapeutyczne</Title>
              </>
            }
            isSearchBar={false}
            tableDataRows={tableDataRows}
            isDataDownloaded={true}
            headCells={headCells}
            actions={generateActions()}
          />
        </AreasContainer>
      )}
      {isModalOpened && (
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.DELETE_MODAL && (
            <DeleteModal
              handleNo={() => closeModal()}
              handleYes={deleteHandlerYes}
              textAdded={"ten obszar terapeutyczny"}
            />
          )}
          {modalType === ModalType.DELETE_CONFIRMATION_MODAL && (
            <ConfirmationModal
              text={
                <p>
                  Obszar terapeutyczny został <b>usunięty poprawnie</b>.
                </p>
              }
            />
          )}
        </Modal>
      )}
    </AdminLayout>
  );
});
