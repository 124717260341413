import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { SearchBar } from "../../../shared/ui/searchBar/SearchBar";
import { useMyCases } from "./useMyCases.vm";
import { observer } from "mobx-react-lite";
import { CaseCard } from "../../../shared/user/cards/CaseCard";
import { NoCases, Section } from "./MyCases.styled";
import { Grid } from "@mui/material";
import { Button } from "../../../shared/button/Button";
import { MuiPagination } from "../../../shared/pagination/MuiPagination";
import { MultipleSelect } from "../../../shared/ui/multipleSelect/MultipleSelect";
import { coinfectionsSettings, statusesSettings } from "./MyCases.helper";
import theme from "../../../../theme/theme";

export const MyCases = observer(() => {
  const {
    filteredListOfCasesByCurrentPage,
    addCase,
    searchValue,
    onChangeSearchHandler,
    pageCount,
    currentPage,
    setCurrentPage,
    filterCoinfections,
    filterStatus,
    filterForms,
    switchFilters,
    switchCoinfectionFilters,
    switchFormFilters,
    forms,
  } = useMyCases();

  return (
    <UserLayout>
      <Grid container>
        <Grid item xs={12}>
          <Button
            clickHandler={addCase}
            text={"+ dodaj nowy opis przypadku"}
            backgroundColor={theme.colors.orange}
            color={theme.colors.b3}
            width={"263px"}
            size={"tiny"}
          />
        </Grid>
        <Grid item xs={12} mt={2} display={"flex"} gap={1}>
          <Grid item xs={3}>
            <MultipleSelect
              elementName={"filterStatus"}
              staticPlaceholder="Status"
              options={[
                { id: 0, title: "Wszystko" },
                ...Object.values(statusesSettings),
              ]}
              selectedValues={filterStatus}
              handleChange={(event) => switchFilters(event)}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              elementName={"filterCoinfection"}
              staticPlaceholder="Koinfecja"
              options={[...Object.values(coinfectionsSettings)]}
              selectedValues={filterCoinfections}
              handleChange={(event) => switchCoinfectionFilters(event)}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              elementName={"filterForm"}
              staticPlaceholder="Formularz"
              options={forms ? [{ id: 0, title: "Wszystko" }, ...forms] : []}
              selectedValues={filterForms}
              handleChange={(event) => switchFormFilters(event)}
            />
          </Grid>
          <Grid item xs={3}>
            <SearchBar
              filterValue={searchValue}
              filterChangeHandler={onChangeSearchHandler}
            />
          </Grid>
        </Grid>
      </Grid>
      <Section>
        {filteredListOfCasesByCurrentPage &&
        filteredListOfCasesByCurrentPage.length > 0 ? (
          <Grid
            container
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid container gap={"20px"} display={"flex"}>
              {filteredListOfCasesByCurrentPage.map(
                ({
                  status,
                  id,
                  customId,
                  coInfection,
                  title,
                  updatedAt,
                  area,
                  form,
                }) => (
                  <Grid item width={"100%"} maxWidth={"15%"} key={id}>
                    <CaseCard
                      area={area}
                      form={form}
                      key={id}
                      id={id}
                      status={status}
                      customId={customId}
                      updatedAt={updatedAt}
                      coInfection={coInfection}
                      title={title}
                    />
                  </Grid>
                )
              )}
            </Grid>
            <MuiPagination
              count={pageCount}
              page={currentPage}
              onChange={(e, value: number) => {
                setCurrentPage(value);
              }}
            />
          </Grid>
        ) : (
          <NoCases>Brak opisów przypadków.</NoCases>
        )}
      </Section>
    </UserLayout>
  );
});
