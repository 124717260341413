import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import * as yup from "yup";
import { useFormik } from "formik";

import { ValidationMessages } from '../../../../constants/validationMessages';
import { IAreaForm } from '../../../../interfaces/admin/areas/areaForm';
import { useModal } from "../../modal/useModal.vm";
import { SelectChangeEvent } from '@mui/material';
import { useFormsApi } from '../../../../apiHooks/useFormsApi';
import { IOption } from '../../../../interfaces/common/form/option';
import { useFormsStore } from '../../../../store/hooks';


export const validationSchema = yup.object().shape({
  fullTitle: yup
    .string()
    .required(),
  shortTitle: yup
    .string()
    .required()
});

export const useAreaForm = (actionHandler: (areaForm: IAreaForm) => Promise<string | null>, areaForm?: IAreaForm | null) => {
  const {getForms} = useFormsApi()
  const formsStore = useFormsStore();
  const {forms, isFormsLoaded} = formsStore;
  const {handleModal, isModalOpened} = useModal()
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [message, setMessage] = useState('')

  const [selectedForms, setSelectedForms] = useState<number[]>([])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullTitle: areaForm ? areaForm.fullTitle : '',
      shortTitle: areaForm ? areaForm.shortTitle : '',
      formTypes: areaForm ? areaForm.formTypes : [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {

      if(selectedForms.length > 0){
        const areaFormNew: IAreaForm = {
          fullTitle: values.fullTitle,
          shortTitle: values.shortTitle,
          formTypes:  [...selectedForms],
        }
        const message = await actionHandler(areaFormNew);

        if (message === null && !areaForm) {
          resetForm()
          setSelectedForms([])
        } else if(message !== null) {
          setMessage(message);
        }
      }
    }
  })

  const createErrorMessage = useCallback(() => {
    if (Object.keys(formik.errors).length > 0 ||  selectedForms.length === 0) {
      return ValidationMessages.INVALID_OR_EMPTY_FORM + ' ' + ValidationMessages.INVALID_FORM;
    }

    return ""
  }, [formik.errors, selectedForms])

  useEffect(() => {
    void getForms()
  }, [getForms])

  useEffect(() => {
    if (formik.submitCount !== 0) {
      setMessage(createErrorMessage());
      const isValid = Object.keys(formik.errors).length === 0 && selectedForms.length > 0;
      setIsButtonActive(isValid);
    } else {
      const isValid = !!formik.values.fullTitle && !!formik.values.shortTitle && selectedForms.length > 0;
      setIsButtonActive(isValid);
    }
  }, [createErrorMessage, formik.errors, formik.submitCount, selectedForms, formik.values.fullTitle, formik.values.shortTitle])

  useEffect(() => {
    setSelectedForms(areaForm ? areaForm.formTypes : [])
  }, [areaForm, setSelectedForms])


  const handleChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedForms.includes(0)) {
        setSelectedForms(selectedValues.filter(value => value !== 0));
      } else {
        if (selectFormsOptions) {
          setSelectedForms([...selectFormsOptions.map((option: IOption) => option.id), 0]);
        }
      }
    } else {
      if (selectedForms.includes(0)) {
        setSelectedForms([]);
      } else {
        setSelectedForms(selectedValues);
      }
    }
  };

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  const selectFormsOptions: IOption[] = forms ? [...forms.map(form => ({title: form.title, id: form.id}))] : [];

  return {
    formik,
    isButtonActive,
    confirmForm,
    message,
    handleModal,
    isModalOpened,
    handleChange,
    selectFormsOptions,
    selectedForms,
    forms,
    isFormsLoaded
  }
}