// TODO delete this file
import { IStage } from "../../../../../interfaces/user/draftCase/progressBar";
import { FifthStage } from "../stages/fifthStage/FifthStage";
import { SixthStage } from "../stages/sixthStage/SixthStage";
import { FirstStage } from "../stages/firstStage/FirstStage";
import { ThirdStage } from "../stages/thirdStage/ThirdStage";
import { SecondStage } from "../stages/secondStage/SecondStage";
import { FourthStage } from "../stages/fourthStage/FourthStage";

export enum Stages {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
}

export const stages: IStage[] = [
  {
    stage: Stages.FIRST,
    component: <FirstStage />,
    stagesSequence: [
      { access: false, stage: null },
      { access: true, stage: Stages.SECOND },
    ],
    name: "Pacjent",
    isStepper: true,
  },
  {
    stage: Stages.SECOND,
    component: <SecondStage />,
    stagesSequence: [
      { access: true, stage: Stages.FIRST },
      { access: true, stage: Stages.THIRD },
    ],
    name: "Rozpoznanie",
    isStepper: true,
  },
  {
    stage: Stages.THIRD,
    component: <ThirdStage />,
    stagesSequence: [
      { access: true, stage: Stages.SECOND },
      { access: true, stage: Stages.FOURTH },
    ],
    name: "Kwalifikacja",
    isStepper: true,
  },
  {
    stage: Stages.FOURTH,
    component: <FourthStage />,
    stagesSequence: [
      { access: true, stage: Stages.THIRD },
      { access: true, stage: Stages.FIFTH },
    ],
    name: "Przebieg leczenia",
    isStepper: true,
  },
  {
    stage: Stages.FIFTH,
    component: <FifthStage />,
    stagesSequence: [
      { access: true, stage: Stages.FOURTH },
      { access: false, stage: null },
    ],
    name: "Gratulacje!",
    isStepper: false,
  },
  {
    stage: Stages.SIXTH,
    component: <SixthStage />,
    stagesSequence: [
      { access: true, stage: Stages.FOURTH },
      { access: false, stage: null },
    ],
    isStepper: false,
  },
];

export const MAX_STAGE = stages.length;
