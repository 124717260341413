import React from "react";
import { observer } from "mobx-react-lite";

import { usePublishingUser } from "./usePublishingUser";
import { AdminLayout } from "../../../shared/admin/layout/AdminLayout";
import { CustomTable } from "../../../shared/customTables/customTable/CustomTable";
import { DeleteModal } from "../../../shared/modal/sharedModals/deleteModal/DeleteModal";
import { Modal } from "../../../shared/modal/Modal";
import { ConfirmationModal } from "../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal";
import { UserForm } from "../../../shared/admin/userForm/UserForm";
import { ModalType } from "../../../../constants/modalType";
import { AdminLink } from "../../../../utils";

export const PublishingUsers = observer(() => {
  const {
    publishingUsersListIsNotNull,
    cellsSwitcher,
    headCells,
    generateActions,
    tableDataRows,
    modalType,
    isModalOpened,
    editHandler,
    deleteHandlerYes,
    userEdit,
    closeModal,
    addedContent,
  } = usePublishingUser();

  return (
    <AdminLayout>
      {publishingUsersListIsNotNull && (
        <CustomTable
          searchBarAddedContent={addedContent}
          cellsSwitcher={cellsSwitcher}
          isSearchBar={true}
          tableDataRows={tableDataRows}
          isDataDownloaded={true}
          headCells={headCells}
          actions={generateActions()}
        />
      )}
      {isModalOpened && (
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.DELETE_MODAL && (
            <DeleteModal
              handleNo={() => closeModal()}
              handleYes={deleteHandlerYes}
              textAdded={"tego użytkownika"}
            />
          )}
          {modalType === ModalType.DELETE_CONFIRMATION_MODAL && (
            <ConfirmationModal
              text={
                <p>
                  Użytkownik został <b>usunięty poprawnie</b>.
                </p>
              }
            />
          )}
          {modalType === ModalType.EDIT_MODAL && (
            <UserForm
              handleNo={() => closeModal()}
              isEdit={true}
              link={AdminLink.PUBLISHING_USERS}
              userForm={userEdit}
              actionHandler={editHandler}
            />
          )}
          {modalType === ModalType.EDIT_CONFIRMATION_MODAL && (
            <ConfirmationModal
              text={
                <p>
                  Użytkownik został <b>edytowany poprawnie</b>.
                </p>
              }
            />
          )}
        </Modal>
      )}
    </AdminLayout>
  );
});
